/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Nope from 'nope-validator'
import Dinero from 'dinero.js'

import Text from '../../simple/text/Text'
import H1 from '../../simple/heading/H1'

import Button from '../../simple/button/Button'

// Resources
import { content } from '../../../resources/content'
import FormField from '../../simple/formField/FormField'
import TextInput from '../../simple/input/TextInput'
import OptionButtons from '../../compound/optionButtons/OptionButtons'
import Box from '../../simple/box/Box'
import Form from '../../simple/form/Form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function PanelEarnings (props) {

  const [formValues, setFormValues] = useState({})
  useEffect(() => {
    setFormValues(props.formValues)
  }, [props.formValues])

  const [validationShape, setValidationShape] = useState({})

  useEffect(() => {
    var shape = {
      numApplicants: Nope.string().required(),
      app1Salary: Nope.string().required(),
      app1Bonus: Nope.string().required()
    }
    if (formValues?.numApplicants && formValues?.numApplicants === '2') {
      shape.app2Salary = Nope.string().required()
      shape.app2Bonus = Nope.string().required()
    }

    setValidationShape(shape)
  }, [formValues.numApplicants])

  const Panel = Nope.object().shape(validationShape)

  const validateFormField = (key) => {
    const error = Panel.validate(formValues)

    // return an error
    if (error?.[key]) {
      return {
        message: error[key],
        status: 'error'
      }
    }
  }


  return (
    <Form
      value={formValues}
      validate='blur'
      onChange={(nextValue) => props.updateValues(nextValue)}
      onSubmit={() => props.nextStep()}
      style={{ marginBottom: '40px' }}
    >
      <H1 weight='bold' color='white'>{content.wizard.earnings.title}</H1>

      <Box gap='xsmall'>
        <Text color='white' size='small' margin={{ top: 'small', bottom: 'small' }}>{content.wizard.earnings.basic_income_label}</Text>
      </Box>
      <Box direction='row-responsive' gap='small'>
        <FormField
          name='app1Salary'
          width='100%'
          validate={() => validateFormField('app1Salary')}
          direction='row'
          round='small'
          border={{ color: 'white', size: 'small' }}
          elevation='standard'
          background='altBackground'
          style={{ position: 'relative' }}
        >
          <TextInput
            id='app1Salary'
            name='app1Salary'
            plain
            width='large'
            type='tel'
            focusIndicator={false}
            icon={<FontAwesomeIcon icon='fa-solid fa-sterling-sign' color='white' />}
            onChange={(event) => {
              var theseFormValues = formValues
              var amount = event.target.value ? parseInt(event.target.value.match(/\d+/gi).join('')) : 0
              theseFormValues.app1Salary = Dinero({ amount, currency: 'GBP', precision: 0 }).toFormat('0,0')
              props.updateValues(theseFormValues)
            }}
          />
        </FormField>
      </Box>


      <Box gap='xsmall'>
        <Text color='white' size='small' margin={{ top: 'small', bottom: 'small' }}>{content.wizard.earnings.overtime_label}</Text>
      </Box>
      <Box direction='row-responsive' gap='small'>
        <FormField
          name='app1Bonus'
          width='100%'
          validate={() => validateFormField('app1Bonus')}
          direction='row'
          round='small'
          border={{ color: 'white', size: 'small' }}
          elevation='standard'
          background='altBackground'
          style={{ position: 'relative' }}
        >
          <TextInput
            id='app1Bonus'
            name='app1Bonus'
            plain
            width='large'
            type='tel'
            focusIndicator={false}
            icon={<FontAwesomeIcon icon='fa-solid fa-sterling-sign' color='white' />}
            onChange={(event) => {
              var theseFormValues = formValues
              var amount = event.target.value ? parseInt(event.target.value.match(/\d+/gi).join('')) : 0
              theseFormValues.app1Bonus = Dinero({ amount, currency: 'GBP', precision: 0 }).toFormat('0,0')
              props.updateValues(theseFormValues)
            }}
          />
        </FormField>
      </Box>

      {formValues?.numApplicants === '2' &&
        <>
          <H1 weight='bold' color='white'>{content.wizard.earnings.title_other}</H1>
          <Box gap='xsmall'>
            <Text color='white' size='small' margin={{ top: 'small', bottom: 'small' }}>{content.wizard.earnings.basic_income_label}</Text>
          </Box>
          <Box direction='row-responsive' gap='small'>
            <FormField
              name='app2Salary'
              width='100%'
              validate={() => validateFormField('app2Salary')}
              direction='row'
              round='small'
              border={{ color: 'white', size: 'small' }}
              elevation='standard'
              background='altBackground'
              style={{ position: 'relative' }}
            >
              <TextInput
                id='app2Salary'
                name='app2Salary'
                plain
                width='large'
                type='tel'
                focusIndicator={false}
                icon={<FontAwesomeIcon icon='fa-solid fa-sterling-sign' color='white' />}
                onChange={(event) => {
                  var theseFormValues = formValues
                  var amount = event.target.value ? parseInt(event.target.value.match(/\d+/gi).join('')) : 0
                  theseFormValues.app2Salary = Dinero({ amount, currency: 'GBP', precision: 0 }).toFormat('0,0')
                  props.updateValues(theseFormValues)
                }}
              />
            </FormField>
          </Box>
          <Box gap='xsmall'>
            <Text color='white' size='small' margin={{ top: 'small', bottom: 'small' }}>{content.wizard.earnings.overtime_label}</Text>
          </Box>
          <Box direction='row-responsive' gap='small'>
            <FormField
              name='app2Bonus'
              width='100%'
              validate={() => validateFormField('app2Bonus')}
              direction='row'
              round='small'
              border={{ color: 'white', size: 'small' }}
              elevation='standard'
              background='altBackground'
              style={{ position: 'relative' }}
            >
              <TextInput
                id='app2Bonus'
                name='app2Bonus'
                plain
                type='tel'
                width='large'
                focusIndicator={false}
                icon={<FontAwesomeIcon icon='fa-solid fa-sterling-sign' color='white' />}
                onChange={(event) => {
                  var theseFormValues = formValues
                  var amount = event.target.value ? parseInt(event.target.value.match(/\d+/gi).join('')) : 0
                  theseFormValues.app2Bonus = Dinero({ amount, currency: 'GBP', precision: 0 }).toFormat('0,0')
                  props.updateValues(theseFormValues)
                }}
              />
            </FormField>
          </Box>
        </>}
      <Box direction='row-responsive' gap='small' width='medium' margin={{ top: 'medium' }}>
        <Button
          label={content.wizard.controls.button_results}
          secondary
          type='submit'
          fill
        />
      </Box>

    </Form>)
}

export default PanelEarnings
