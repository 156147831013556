/* eslint-disable */
const getWelcomMessage = () => {
  let welcomeMessage = {}
  let i = 1

  while (1) {
    if (!process.env[`REACT_APP_WELCOME_MESSAGE_P_${i}`]) {
      break
    }

    welcomeMessage[`paragraph_${i}`] = process.env[`REACT_APP_WELCOME_MESSAGE_P_${i}`]
    ++i
  }

  return welcomeMessage
}


export const content = {
  wizard: {
    intro: {
      title: process.env.REACT_APP_TITLE,
      ...getWelcomMessage(),
    },
    new_home: {
      title: "Information on your new home",
      first_home_label: "What is your situation?",
      first_home_yes: "I'm purchasing my first home",
      first_home_no: "I'm moving home",
      property_price_label:
        "How much is the property you are looking to purchase?",
      deposit_label:
        "What deposit are you thinking of putting towards your home?",
      term_label: "What mortgage term (in years) would you like?",
      deposit_minimum:
        "(Ideally you would be looking at a minimum of 5% - We’ve automatically calculated this for you)",
    },
    about_you: {
      title: "A little bit about you",
      title_other: "A little bit about the other person",
      name_label: "Name",
      email_label: "Email Address",
      phone_label: "Contact Number",
      dob_label: "Date of Birth",
    },
    earnings: {
      title: "Your Annual Earnings",
      title_other: "Other Person's Annual Earnings",
      your_income_title: "Your Income",
      other_income_title: "Other Person's Income",
      basic_income_label:
        "Basic Income before Tax (Include any car allowance):",
      overtime_label: "Overtime/Bonuses/Commission:",
      with_someone_label: "Are you looking to purchase with someone else?",
      with_someone_yes: "With someone else",
      with_someone_no: "Just me",
    },
    controls: {
      button_get_started: "Let's get started",
      button_next: "next",
      button_results: "Let's see my Results",
    },
    results: {
      loading: "Searching Live with over 70 Lenders.",
      be_patient: "Please wait, this can take up to 60 seconds...",
      call_me_asap: 'Call Me Asap',
      arrange_a_callback: 'Arrange a Callback'
    },
  },
  signup: {
    applicant_label: "APPLICANT",
    property_label: "WHAT PROPERTY ARE YOU LOOKING AT?",
    interested_label: "I'M INTERESTED IN A PROPERTY AT",
    button_label_add: "+ Add Applicant",
    button_label_remove: "- Remove Applicant",
    field_labels: {
      type: "I'm the*",
      type_options: ["Purchaser", "Sales Negotiator"],
      site_development: "Development Name*",
      site: "Site Name*",
      builder: "Builder/Region*",
      submitted_by: "Submitted By*",
      plot: "Plot Number",
      applicants: { name: "Name*", email: "Email*", phone: "Phone*" },
      purchase: "Purchase Price (£)*",
      date: "Date*",
      time: "Time*",
      consent_label_sales_negotiator:
        "I confirm that consent has been given for my data to be passed to Meridian Mortgages and {builder}.",
      consent_label_client:
        "I confirm that I have given consent for my data to be passed to Meridian Mortgages.",
    },
    best_label: "WHEN'S BEST TO CALL YOU?",
    best_label_lowercase: "When's best to call you?",
    calculator_label:
      "Would you like to find out how much it could cost you with our quick calculator?",
    best_placeholder: "Any additional information including the Development you are interested in?",
    caveat_1:
      "Your home may be repossessed if you do not keep up repayments on your mortgage.",
    caveat_2:
      "There may be a fee for mortgage advice of up to 1% of the amount borrowed. A typical fee is £99, but this will depend upon your circumstances.",
    submit_button_label: "Submit",
    take_a_look_button_label: "Yes!, Let's take a look",
    call_button_label: "I'd just like a call",
  },
  calculator: {
    title: "To start we quickly need an overview of your earning.",
    applicant_label: "ANNUAL INCOME OF APPLICANT",
    field_labels: {
      applicants: {
        income: "Basic Income (Include Any Car Allowance)",
        otherIncome: "Overtime/Bonusses/Comission",
      },
      first_buyer_label: "Are you a first-time buyer?",
      second_home_label: "Is this your second home?",
      where_label: "Where are you looking to purchase?",
      deposit_label:
        "What deposit are you thinking of, ideally you would be looking at a minimum of 5%",
      term_label:
        "What term are would you like to take the mortgage over?, On average we would start at 30 years but please change if you would like to be more specific",
    },
    submit_button_label: "Calculate",
    details_label:
      "We now just need a few details about the new home at development for £purchasePrice",
  },
  calculatorResult: {
    title: "Here's your results sourced from",
    title_line2: "LIVE Mortgage Lenders!",
    edit_label:
      'Please feel free to amend your "New Home Information" to update your results.',
    edit_link: 'Edit "New Home Information"',
    show_label: "Show result for",
    accordion: {
      straight_label: "Straight Purchase",
      help_label: "Help to Buy",
      shared_label: "Shared Ownership",
    },
    submit_button_label: "Submit",
    description_text:
      "These results are based on limited information. Now we need a quick conversation to confirm the above and look at getting you into your new home!",
    caveat_text1:
      "There may be a fee for mortgage advice of up to 1% of the amount borrowed. A typical fee is £99, but this will depend upon your circumstances.",
    caveat_text2:
      "Your home may be repossessed if you do not keep up repayments on your mortgage.",
    consent_label:
      "I confirm that consent has been given for my data to be passed to Meridian Mortgages and {builder}.",
  },
  success: {
    title: "Thank you for your submission, we will be in touch soon!",
  },
  call: {
    date_future_text: "The date must be in the future",
  },
  acceptTC: "You must consent to these terms to continue",
};
