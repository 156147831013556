/* eslint-disable */
import React from 'react'

// Components
import Box from '../components/simple/box/Box.js'
import Image from '../components/simple/image/Image.js'
import { settings } from '../config.js'

function Background (props) {

  var background = {
    color: 'background',
    image: 'url(/background.jpg?r=0001)'
  }

  if (settings.darkMode) {
    background.color = 'altBackground'
    background.image = 'url(/background-house-outline.svg)'
  } else if (!settings.darkMode && props.isSuccessPage) {
    background.image = 'url(/background-yellow.png?r=0001)'
  }

  var backgroundPosition = !props.isSuccessPage ? 'center 0px' : ''
  if (props.windowSize.width < 1024) {

  }

  if (props.windowSize.width < 840) {
    backgroundPosition = '35% 0px'
  }

  if (props.windowSize.width < 768) {
  }

  if (props.windowSize.width < 560) {
  }

  var wrapperStyle = {
    backgroundPosition,
    minHeight: '100vh',
    position: 'relative'
  }

  if (settings.darkMode) {
    backgroundPosition = '0 0'
    wrapperStyle['background-position'] = '0 75vh'
    wrapperStyle['background-size'] = '95vh'
  }

  return (
    <Box
      margin={{ top: 'none' }}
      align='center'
      style={wrapperStyle}
      pad='small'
      radius='none'
      background={background}
    >
      {props.children}
    </Box>
  )
}

export default Background
