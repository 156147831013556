/* eslint-disable */
import React from 'react'

// Components
import Box from '../components/simple/box/Box.js'
import Image from '../components/simple/image/Image.js'
import { settings } from '../config.js'
function Logo (props) {
  var r = '001'
  var logoPath = `/meridian-logo.png?r=${r}`
  var alignment = 'center'


  var logoLeft = '-190px'
  if (props.windowSize.width < 1024) {
    logoLeft = '-200px'
  }

  if (props.windowSize.width < 840) {
    logoLeft = 'auto'
  }


  if (settings.darkMode) {
    alignment = 'start'

    logoLeft = '40px'

    if (settings.showCalaBranding) {
      logoPath = `${process.env.REACT_APP_LOGO_PATH}?r=${r}`
    } else {
      logoPath = `${process.env.REACT_APP_LOGO_PATH}?r=${r}`
    }
  }



  return (
    <Box
      height='13vh'
      style={{
        position: 'relative',
      }}
      alignSelf={alignment}
      margin={{ bottom: 'xsmall' }}
    >
      <Image fit='contain' alignSelf={alignment} src={logoPath} />
    </Box>
  )
}

export default Logo
