/* eslint-disable */
import React, { useEffect, useState } from "react";
import Nope from "nope-validator";
import Dinero from "dinero.js";

import Text from "../../simple/text/Text";
import H1 from "../../simple/heading/H1";

import Button from "../../simple/button/Button";

// Resources
import { content } from "../../../resources/content";
import FormField from "../../simple/formField/FormField";
import TextInput from "../../simple/input/TextInput";
import OptionButtons from "../../compound/optionButtons/OptionButtons";
import Box from "../../simple/box/Box";
import Form from "../../simple/form/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function PanelIntro(props) {
  const [formValues, setFormValues] = useState({});
  const [userHasSetDeposit, setUserHasSetDeposit] = useState(false);
  useEffect(() => {
    setFormValues(props.formValues);
  }, [props.formValues]);

  const [windowSize, setWindowSize] = useState(props.window);
  useEffect(() => {
    setWindowSize(props.window);
  }, [props.window]);

  const Panel = Nope.object().shape({
    firstHome: Nope.string().required(),
    propertyPrice: Nope.string().required(),
    deposit: Nope.string().required(),
  });

  const validateFormField = (key) => {
    const error = Panel.validate(formValues);

    if (key === 'deposit') {
      const propertyPrice = formValues.propertyPrice ? parseInt(formValues.propertyPrice.replace(/\,/g, "")) : 0
      const depositAmount = formValues.deposit ? parseInt(formValues.deposit.replace(/\,/g, "")) : 0

      // make sure the deposit isn't more than the property
      if (depositAmount >= propertyPrice) {
        return {
          message: 'The deposit must be smaller than the property price',
          status: 'error'
        };
      }

      // make sure the deposit isn't less than 5% of property
      if (depositAmount < propertyPrice * 0.05) {
        return {
          message: 'A minimum 5% deposit is required',
          status: 'error'
        };
      }

    }


    // return an error
    if (error?.[key]) {
      return {
        message: error[key],
        status: "error",
      };
    }
  };

  const autoCalculateDeposit = () => {
    if (formValues.propertyPrice && !userHasSetDeposit) {
      const price = formValues.propertyPrice.replace(/\,/g, "");
      const deposit = Math.ceil((parseInt(price) * 5) / 100);
      return Dinero({
        amount: deposit,
        currency: "GBP",
        precision: 0,
      }).toFormat("0,0");
    }

    // otherwise, just return the current deposit
    return formValues.deposit;
  };

  var optionButtonWidth = "200px";
  if (windowSize.width < 425) {
    optionButtonWidth = "45vw";
  }

  return (
    <Form
      value={formValues}
      validate="blur"
      onChange={(nextValue) => props.updateValues(nextValue)}
      onSubmit={() => props.nextStep()}
      style={{ marginBottom: '40px' }}
    >
      <H1 weight="bold" color="white">
        {content.wizard.new_home.title}
      </H1>
      <Box gap="xsmall">
        <Text
          color="white"
          size="small"
          margin={{ top: "small", bottom: "small" }}
        >
          {content.wizard.new_home.first_home_label}
        </Text>
      </Box>
      <Box direction="row-responsive" gap="small">
        <FormField
          validate={() => validateFormField("firstHome")}
          htmlFor="firstHome"
          name="firstHome"
          style={{
            position: "relative",
          }}
        >
          <OptionButtons
            id="firstHome"
            name="firstHome"
            buttonBackground="altSecondary"
            buttonActive="secondary"
            buttonHeight="130px"
            buttonWidth={optionButtonWidth}
            fontSize="medium"
            fontWeight="normal"
            options={[
              {
                label: content.wizard.new_home.first_home_yes,
                value: "1",
                iconUrl: "/icon-house.svg",
              },
              {
                label: content.wizard.new_home.first_home_no,
                value: "0",
                iconUrl: "/icon-house-moving.svg",
              },
            ]}
          />
        </FormField>
      </Box>

      <Box gap="xsmall">
        <Text
          color="white"
          size="small"
          margin={{ top: "small", bottom: "small" }}
        >
          <FontAwesomeIcon icon={["fa", "sterlingSign"]} color="white" />{" "}
          {content.wizard.new_home.property_price_label}
        </Text>
      </Box>
      <Box direction="row-responsive" gap="small">
        <FormField
          name="propertyPrice"
          width="100%"
          validate={() => validateFormField("propertyPrice")}
          direction="row"
          round="small"
          border={{ color: "white", size: "small" }}
          elevation="standard"
          background="altSecondary"
          style={{ position: "relative" }}
        >
          <TextInput
            id="propertyPrice"
            name="propertyPrice"
            icon={
              <FontAwesomeIcon icon="fa-solid fa-sterling-sign" color="white" />
            }
            plain
            width="large"
            focusIndicator={false}
            type="tel"
            onChange={(event) => {
              var theseFormValues = formValues;
              // format the date
              var amount = event.target.value
                ? parseInt(event.target.value.match(/\d+/gi).join(""))
                : 0;
              theseFormValues.propertyPrice = Dinero({
                amount,
                currency: "GBP",
                precision: 0,
              }).toFormat("0,0");
              theseFormValues.deposit = autoCalculateDeposit();
              props.updateValues(theseFormValues);
            }}
          />
        </FormField>
      </Box>

      <Box gap="xsmall">
        <Text
          color="white"
          size="small"
          margin={{ top: "small", bottom: "none" }}
        >
          {content.wizard.new_home.deposit_label}
        </Text>
        <Text
          color="white"
          size="xsmall"
          margin={{ top: "none", bottom: "small" }}
        >
          {content.wizard.new_home.deposit_minimum}
        </Text>
      </Box>
      <Box direction="row-responsive" gap="small">
        <FormField
          name="deposit"
          width="100%"
          validate={() => validateFormField("deposit")}
          direction="row"
          round="small"
          border={{ color: "white", size: "small" }}
          elevation="standard"
          background="altSecondary"
          style={{ position: "relative" }}
        >
          <TextInput
            id="deposit"
            name="deposit"
            plain
            width="large"
            type="tel"
            icon={
              <FontAwesomeIcon icon="fa-solid fa-sterling-sign" color="white" />
            }
            focusIndicator={false}
            onChange={(event) => {
              setUserHasSetDeposit(true);
              var theseFormValues = formValues;
              // format the date
              var amount = event.target.value
                ? parseInt(event.target.value.match(/\d+/gi).join(""))
                : 0;
              theseFormValues.deposit = Dinero({
                amount,
                currency: "GBP",
                precision: 0,
              }).toFormat("0,0");
              props.updateValues(theseFormValues);
            }}
          />
        </FormField>
      </Box>

      <Box direction="row-responsive" gap="small" margin={{ top: 'large' }}>
        <Button
          label={content.wizard.controls.button_next}
          secondary
          type="submit"
          fill
        />
      </Box>
    </Form>
  );
}

export default PanelIntro;
