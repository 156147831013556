/* eslint-disable */
import React from 'react'

// Components
import Box from '../components/simple/box/Box.js'
import Text from '../components/simple/text/Text'

function Cookies (props) {
  return (
    <Box
      gap='xsmall'
    >
      <Box round='small' style={{ borderBottom: 'none' }} margin={{ bottom: 'medium' }}>
        <Box background='primaryDark' round='small' pad='medium' margin={{ top: 'medium', bottom: 'large' }}>
          <h2 style={{ color: 'white' }}>What cookies Meridian use and why</h2>
          <p style={{ color: 'white' }}>Cookies are small text files, which often include a unique identifier that is sent by a web server to your computer, mobile phone or any other internet enabled device (“device”) when you visit a website. Cookies are widely used in order to make websites work efficiently and help provide us with business and marketing information to enable us to make informed decisions on the development of our websites.</p>
          <p style={{ color: 'white' }}>We use the following types of cookies:</p>
          <ul>
            <li style={{ color: 'white', marginBottom: '10px' }}><strong>Essential</strong>&nbsp;- Necessary cookies help make a website usable by enabling basic functions like page navigation and access to secure areas of the website. The website cannot function properly without these cookies.</li>
            <li style={{ color: 'white', marginBottom: '10px' }}><strong>Performance</strong>&nbsp;- Performance cookies help owners understand how the website is used by visitors. This information can then be used to improve the way the website works.</li>
            <li style={{ color: 'white', marginBottom: '10px' }}><strong>Preference</strong>&nbsp;- Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.</li>
            <li style={{ color: 'white', marginBottom: '10px' }}><strong>Statistics</strong>&nbsp;- Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.</li>
            <li style={{ color: 'white', marginBottom: '10px' }}><strong>Marketing</strong>&nbsp;- Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third-party advertisers.</li>
          </ul>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}><strong>Name</strong></p>
                </td>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}><strong>Description</strong></p>
                </td>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}><strong>Purpose</strong></p>
                </td>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}><strong>Type</strong></p>
                </td>
              </tr>
            </thead>
            <tbody>


              <tr>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}>_ga</p>
                </td>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}>To generate statistical data on how visitor uses the website</p>
                </td>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}>Statistics</p>
                </td>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}>2 years</p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}>_gat</p>
                </td>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}>Used by Google Analytics to control request rate</p>
                </td>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}>Statistics</p>
                </td>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}>1 day</p>
                </td>
              </tr>
              <tr>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}>_gid</p>
                </td>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}>To generate statistical data on how visitor uses the website</p>
                </td>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}>Statistics</p>
                </td>
                <td style={{ padding: '5px' }}>
                  <p style={{ color: 'white' }}>1 day</p>
                </td>
              </tr>
            </tbody>
          </table>
          <p style={{ color: 'white' }}><strong>How to withdraw consent to, control, and/or delete cookies</strong></p>
          <p style={{ color: 'white' }}>It is possible to remove cookies or block them from recording this information. You also have the option of blocking third party cookies only.</p>
          <p style={{ color: 'white' }}>This option is generally found within the Options menu then Privacy section of your particular browser. Each browser is slightly different and constantly updating.</p>
          <p style={{ color: 'white' }}>For more information consult the Help menu of your browser. The links below provide information on how to manage cookies on popular browsers:</p>
          <ul>
            <li style={{ color: 'white', marginBottom: '10px' }}><a style={{ color: 'white' }} href='https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=en'>Google Chrome</a></li>
            <li style={{ color: 'white', marginBottom: '10px' }}><a style={{ color: 'white' }} href='https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy'>Microsoft Edge</a></li>
            <li style={{ color: 'white', marginBottom: '10px' }}><a style={{ color: 'white' }} href='https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences'>Mozilla Firefox</a></li>
            <li style={{ color: 'white', marginBottom: '10px' }}><a style={{ color: 'white' }} href='https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies'>Microsoft Internet Explorer</a></li>
            <li style={{ color: 'white', marginBottom: '10px' }}><a style={{ color: 'white' }} href='https://www.opera.com/help/tutorials/security/privacy/'>Opera</a></li>
            <li style={{ color: 'white', marginBottom: '10px' }}><a style={{ color: 'white' }} href='https://support.apple.com/en-gb/safari'>Safari</a></li>
          </ul>
          <p style={{ color: 'white' }}>To find information relating to other browsers, visit the browser developer's website.</p>
          <p style={{ color: 'white' }}>This information has been provided to show our commitment to legislation regarding cookie awareness so that you can understand what cookies we use and why we use them. This ensures transparency in the data collected on you.</p>
          <p style={{ color: 'white' }}><strong>Further information</strong></p>
          <p style={{ color: 'white' }}><a style={{ color: 'white' }} href='https://ico.org.uk/for-organisations/guide-to-pecr/guidance-on-the-use-of-cookies-and-similar-technologies'><strong>https://ico.org.uk/for-organisations/guide-to-pecr/guidance-on-the-use-of-cookies-and-similar-technologies/</strong></a></p>
          <p style={{ color: 'white' }}><a style={{ color: 'white' }} href='http://www.allaboutcookies.org/'><strong>https://www.allaboutcookies.org</strong></a></p>
          <p style={{ color: 'white' }}>This policy was last updated 5<sup>th</sup> March 2021</p>
        </Box>
      </Box>
    </Box>
  )
}

export default Cookies
