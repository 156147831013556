/* eslint-disable */
import React, { useState, useEffect } from "react";

// 3rd Party
import Nope from "nope-validator";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Dinero from "dinero.js";

// Components
import Box from "../components/simple/box/Box.js";
import Form from "../components/simple/form/Form";
import Text from "../components/simple/text/Text";
import WizardWrapper from "../components/wizard/WizardWrapper";
import PanelIntro from "../components/wizard/panels/PanelIntro";
import PanelNewHome from "../components/wizard/panels/PanelNewHome";
import PanelAboutYou from "../components/wizard/panels/PanelAboutYou";

// Resources
import { content } from "../resources/content";
import { settings } from "../config.js";

// Services
import LocalStorageService from "../services/LocalStorageService";
import NotificationService from "../services/NotificationService";
import FormService from "../services/FormService";
import PanelEarnings from "../components/wizard/panels/PanelEarnings.js";

function Wizard(props) {
  const apiToken = LocalStorageService.get("apiToken");
  const activeSite = LocalStorageService.get("activeSite");
  const currentUser = LocalStorageService.get("userDetails");

  const [wizardFormValues, setWizardFormValues] = useState({});

  const history = useHistory();
  const onSubmit = async (event) => {};

  const [step, setStep] = useState(0);

  const panels = [
    <PanelIntro
      key="intro"
      nextStep={() => nextStep()}
      updateValues={(data) => updateFormValues(data)}
      formValues={wizardFormValues}
    />,
    <PanelNewHome
      key="newhome"
      window={props.window}
      nextStep={() => nextStep()}
      updateValues={(data) => updateFormValues(data)}
      formValues={wizardFormValues}
    />,
    <PanelAboutYou
      key="aboutyou"
      window={props.window}
      nextStep={() => nextStep()}
      updateValues={(data) => updateFormValues(data)}
      formValues={wizardFormValues}
    />,
    <PanelEarnings
      key="earnings"
      window={props.window}
      nextStep={() => nextStep()}
      updateValues={(data) => updateFormValues(data)}
      formValues={wizardFormValues}
    />,
  ];

  const updateFormValues = (data) => {
    setWizardFormValues(data);
  };

  const nextStep = () => {
    document.getElementById('scroll-wrapper').scrollTo(0, 0)
    window.scrollTo(0, 0)
    if (step + 1 >= panels.length) {
      completeWizard();
      return;
    }

    setStep(step + 1);
  };

  const [queryParam, setQueryParam] = useState({})
  const parseParams = (querystring) => {
    const params = new URLSearchParams(querystring)
    const obj = {}
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key)
      } else {
        obj[key] = params.get(key)
      }
    }
    return obj
  }
  useEffect(() => {
    const params = parseParams(window.location.search)
    setQueryParam(params)
  }, [])

  const completeWizard = async (event) => {
    var responses = [];

    // clean the price
    const pricesToClean = [
      "propertyPrice",
      "deposit",
      "app1Salary",
      "app1Bonus",
      "app2Salary",
      "app2Bonus",
    ];

    var cleanPrices = {};
    for (const field of pricesToClean) {
      cleanPrices[field] = wizardFormValues[field]
        ? parseInt(wizardFormValues[field].replace(/\,/g, ""))
        : 0;
    }

    for (const key in wizardFormValues) {
      var value = wizardFormValues[key];

      // if we have a clean price... use it
      if (cleanPrices[key]) {
        value = cleanPrices[key];
      }

      var item = {
        questionKey: key,
        questionText: key,
        response: value,
      };
      responses.push(item);
    }

    // set the loan amount from the deposit and property price
    responses.push({
      questionKey: "loanAmount",
      questionText: "Loan Amount",
      response: cleanPrices.propertyPrice - cleanPrices.deposit,
    });

    responses.push({
      questionKey: "term",
      questionText: "Mortgage Term",
      response: 30,
    });

    responses.push({
      questionKey: "app1Frequency",
      questionText: "app1Frequency",
      response: "Annually",
    });

    responses.push({
      questionKey: "app2Frequency",
      questionText: "app2Frequency",
      response: "Annually",
    });

    // mocking data for missing properties
    settings.requiredFQGeneralFields.map((item) => {
      if (
        wizardFormValues[item.questionKey] === undefined ||
        wizardFormValues[item.questionKey] === ""
      ) {
        responses.push(item);
      }
    });

    settings.requiredFQApplicantFields.map((item) => {
      for (let i = 1; i <= wizardFormValues.numApplicants; i++) {
        var key = "app" + i + item.questionKey;
        if (
          wizardFormValues[key] === undefined ||
          wizardFormValues[key] === ""
        ) {
          var appMissing = {
            questionKey: key,
            questionText: item.questionText,
            response: item.response,
          };
          responses.push(appMissing);
        }
      }
    });

    // end mocking data for missing properties

    const data = {
      user: settings.userId,
      status: "Draft",
      type: "meridian-signupform",
      reference: "",
      responses: responses,
    };

    const params = {
      siteId: activeSite?.id || '66268ec68c36dccb5ea1477f',
    };

    console.log("this is what I'm about to submit", data.responses);

    var response = await FormService.makeSubmissions(
      settings.apiToken,
      data,
      params
    );
	
	console.log("Wizard: FormService.makeSubmissions: response");
	console.log(response);
	console.log(response.data);
	console.log(response.data.submissionId);
	
    //if (response.error === false) {
    //  NotificationService.error("There was an error saving this submission");
    //  return;
    //}
	
    if (!response.data.submissionId) {
      NotificationService.error("There was an error retrieving this submission");
      return;
    }

    const submissionId = response.data.submissionId;
    const uuid = response.data.uuid;

	console.log("Wizard: FormService.makeSubmissions: response: noerror");
	console.log("submissionId="+submissionId+" submissionId="+uuid);

    history.push({
      pathname: "/wizard-results",
      data: {
        formValues: wizardFormValues,
        submissionId: submissionId,
        responses: data.responses,
        debug: queryParam.debug,
        uuid
      }
    })
	
	console.log("Wizard: Function ended");
	
  }

  const debugRenderFormValues = () => {
    if (!settings.debug) return;

    var toRender = [];
    for (const key in wizardFormValues) {
      const value = wizardFormValues[key];
      toRender.push(
        <Text color="white">
          <Text weight="bold">{key}</Text>
          <Text weight="normal">{value}</Text>
        </Text>
      );
    }

    return (
      <Box elevation="xlarge" border="white" pad="small">
        {toRender}
      </Box>
    );
  };
  return (
    <Box gap="xsmall" pad={{ bottom: 'large' }}>
      {debugRenderFormValues()}
      <WizardWrapper panels={panels} step={step} />
    </Box>
  );
}

export default Wizard;
