/* eslint-disable */
import React, { useState, useEffect } from 'react'

// External Libraries
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Grommet, ResponsiveContext } from 'grommet'
import CookieConsent from "react-cookie-consent";

import Box from './components/simple/box/Box'
import Sidebar from './components/Sidebar'
import SidebarDark from './components/SidebarDark'
import PrivacyLinks from './components/PrivacyLinks'
import ScrollToTop from './components/ScrollToTop'

import ApiService from './services/ApiService'
import { settings } from './config.js'

import Signup from './screens/signup'
import Error404 from './screens/error404'
import Success from './screens/success'
import Calculator from './screens/calculator'
import CalculatorResult from './screens/calculatorResult'
import Cookies from './screens/cookies'

// Stores
import { withStore } from 'react-context-hook'

// Theme
import { projectTheme } from './utils/projectTheme'
import { projectThemeDark } from './utils/projectThemeDark'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome as falHome, faStar as falStar, faPencil as falPencil, faCheck as falCheck, faSterlingSign as falSterlingSign } from '@fortawesome/pro-light-svg-icons'
import { faExclamationTriangle, faStar, faPencil, faCheck, faSterlingSign } from '@fortawesome/pro-solid-svg-icons'

// Router
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import Wizard from './screens/wizard'
import WizardResults from './screens/wizardResults'
import Logo from './components/Logo'
import Background from './components/Background'

function App () {
  library.add([falHome, falStar, falPencil, falCheck, falSterlingSign])
  library.add([faExclamationTriangle, faStar, faPencil, faCheck, faSterlingSign])
  document.body.style.backgroundColor = '#58919d'

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const [isSuccessPage, setIsSuccessPage] = useState(false)

  const setDimension = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  useEffect(() => {
    window.addEventListener('resize', setDimension)

    return () => {
      window.removeEventListener('resize', setDimension)
    }
  }, [windowSize])

  useEffect(() => {}, [])

  var centerWidth = '600px'
  var centerPosition = !isSuccessPage ? {
    position: 'relative',
    top: '50px',
    left: '-175px'
  } : {}

  // var margin = isSuccessPage ? 'auto' : { top: 'size' }
  var margin = { top: 'size' }
  var centerPad

  if (windowSize.width < 1024) {
    centerWidth = '450px'
  }

  if (windowSize.width < 840) {
    centerWidth = '75%'
    centerPosition = {
      position: 'relative',
      top: '100px'
    }
  }

  if (windowSize.width < 768) {
    centerWidth = '80%'
  }

  if (windowSize.width < 560) {
    centerWidth = '100%'
  }


  if (settings.darkMode) {
    centerPosition.top = '20px'
  }


  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <Grommet id='scroll-wrapper' full theme={settings.darkMode ? projectThemeDark : projectTheme}>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} />
        <ResponsiveContext.Consumer>
          {size => {
            return (
              <Background isSuccessPage={isSuccessPage} windowSize={windowSize}>
                <Box alignSelf='center' direction='row-responsive' gap='medium' justify='center' margin={margin} pad={{ horizontal: size }} width='xxlarge'>
                  <Box width={centerWidth} style={centerPosition} pad={centerPad} alignSelf='center'>
                    {!isSuccessPage && <Logo windowSize={windowSize} />}
                    <Switch>
                      <Route
                        exact
                        path='/phase2'
                        render={props => (
                          <Signup
                            {...props}
                            window={windowSize}
                            setIsSuccessPage={setIsSuccessPage}
                            size={size}
                          />
                        )}
                      />
                      <Route
                        exact
                        path='/'
                        render={props => (
                          <Wizard
                            {...props}
                            window={windowSize}
                            setIsSuccessPage={setIsSuccessPage}
                            size={size}
                          />
                        )}
                      />
                      <Route
                        exact
                        path='/wizard-results'
                        render={props => (
                          <WizardResults
                            {...props}
                            window={windowSize}
                            setIsSuccessPage={setIsSuccessPage}
                            size={size}
                          />
                        )}
                      />
                      <Route
                        exact
                        path='/calculator'
                        render={props => (
                          <Calculator
                            {...props}
                            window={windowSize}
                            setIsSuccessPage={setIsSuccessPage}
                            size={size}
                          />
                        )}
                      />
                      <Route
                        exact
                        path='/calculator-result'
                        render={props => (
                          <CalculatorResult
                            {...props}
                            window={windowSize}
                            setIsSuccessPage={setIsSuccessPage}
                            size={size}
                          />
                        )}
                      />
                      <Route
                        exact
                        path='/success'
                        render={props => (
                          <Success
                            {...props}
                            window={windowSize}
                            size={size}
                            setIsSuccessPage={setIsSuccessPage}
                          />
                        )}
                      />
                      <Route
                        exact
                        path='/cookies'
                        render={props => (
                          <Cookies
                            {...props}
                            window={windowSize}
                          />
                        )}
                      />
                      <Route
                       path='*' 
                        render={props => (
                          <Wizard
                            {...props}
                            window={windowSize}
                            setIsSuccessPage={setIsSuccessPage}
                            size={size}
                          />
                        )}
                      />
                    </Switch>
                  </Box>
                  {!isSuccessPage && !settings.darkMode && <Sidebar window={windowSize} size={size} />}
                  {!isSuccessPage && settings.darkMode && <SidebarDark window={windowSize} size={size} />}
                  <CookieConsent
                    location='bottom'
                    buttonText='Okay'
                    cookieName='cookieAcceptance'
                    style={{ background: '#2B373B' }}
                    buttonStyle={{ background: '#e5bd41', color: '#fff', fontSize: '13px', fontWeight: 'bold', borderRadius: '10px' }}
                    expires={150}
                  >
                    By using this site you agreed to our <a style={{ color: '#e5bd41' }} rel='noreferrer' href='/cookies' target='_blank'>cookie</a> and <a rel='noreferrer' href='https://www.meridianmortgages.net/privacy-policy' style={{ color: '#e5bd41' }} target='_blank'>privacy policy</a>.
                  </CookieConsent>
                  <Box><PrivacyLinks windowWidth={windowSize} /></Box>
                </Box>
              </Background>
            )
          }}
        </ResponsiveContext.Consumer>
      </Grommet>
    </Router>
  )
}

export default withStore(App)
