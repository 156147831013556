/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { Select as GSelect } from 'grommet'

/**
 * `Select` renders a Grommet Select component
 */
class Select extends React.Component {
  render () {
    return (
      <GSelect
        {...this.props}
      />)
  }
}

Select.propTypes = {
  /**
   * Custom label to be used by screen readers. When provided, an aria-label will be added to the element.  
   *   
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * How to align along the cross axis when contained in a Box or along the column axis when contained in a Grid.  
   *   
   * "start"  
   * "center"  
   * "end"  
   * "stretch"
   */
  alignSelf: PropTypes.string,
  /**
   * Function that will be called when each option is rendered.  
   * It will be passed (option, index, options, state) where option is the option to render, index is the index of that option in the options array, and state is an object with { active, disabled, selected } keys indicating the current state of the option.  
   *   
   * (option, index, options, { active, disabled, selected }) => {...}
   */
  children: PropTypes.func,
  /**
   * Whether to close the drop when a selection is made.  
   *   
   * **true**  
   * false
   */
  closeOnChange: PropTypes.bool,
  /**
   * Whether the entire select or individual options should be disabled.  
   * An array of numbers indicates the indexes into 'options' of the disabled options.  
   * An array of strings or objects work the same way as the 'value' to indicate which options are disabled.  
   *   
   * true  
   * **false**  
   * [  
   * &nbsp;"number"  
   * &nbsp;"string"  
   * &nbsp;"object"  
   * ]
   */
  disabled: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.array
  ]),
  /**
   * When the options array contains objects, this property indicates how to determine which options should be disabled.  
   * If a string is provided, it is used as the key for each item object and if that key returns truthy, the option is disabled.  
   * If a function is provided, it is called with the option and the return value determines if the option is disabled.  
   *   
   * "string"  
   * "function"
   */
  disabledKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  /**
   * How to align the drop.  
   *   
   * {  
   * top: "top"  
   * &nbsp;&nbsp;"bottom,"  
   * bottom: "top"  
   * &nbsp;&nbsp;"bottom,"  
   *right: "left"  
   * &nbsp;&nbsp;"right,"  
   *left: "left"  
   * &nbsp;&nbsp;"right"  
   *}
   */
  dropAlign: PropTypes.object,
  /**
   * The height of the drop container.  
   *   
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "string"
   */
  dropHeight: PropTypes.string,
  /**
   * Any valid Drop prop.  
   *   
   * "object"
   */
  dropProps: PropTypes.object,
  /**
   * Target where the options drop will be aligned to.  
   * This should be a React reference.  
   * Typically, this is not required as the drop will be aligned to the Select itself by default.  
   *   
   * "object"
   */
  dropTarget: PropTypes.object,
  /**
   * Empty option message to display when no matching results were found  
   *   
   * **"No matches found"  
   */
  emptySearchMessage: PropTypes.string,
  /**
   * Whether when 'plain' it should receive a focus outline.  
   *   
   * true  
   * false
   */
  focusIndicator: PropTypes.bool,
  /**
   * The name of the area to place this inside a parent Grid.  
   *   
   * "a parent grid area name"
   */
  gridArea: PropTypes.string,
  /**
   * A custom icon to be used when rendering the select.  
   * You can use false to not render an icon at all.  
   *   
   * true  
   * false  
   * "function"  
   * "node"
   */
  icon: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.func,
    PropTypes.string
  ]),
  /**
   * When the options array contains objects, this property indicates how to determine the label of each option.  
   * If a string is provided, it is used as the key to retrieve each option's label.  
   * If a function is provided, it is called with the option and the return value indicates the label.  
   *   
   * "string"  
   * "function"
   */
  labelKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  /**
   * The amount of margin around the component.  
   * An object can be specified to distinguish horizontal margin, vertical margin, and margin on a particular side.  
   *   
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * &nbsp;{  
   * &nbsp;&nbsp;"vertical": "...",  
   * &nbsp;&nbsp;"horizontal": "...",  
   * &nbsp;&nbsp;"top": "...",  
   * &nbsp;&nbsp;"bottom": "...",  
   * &nbsp;&nbsp;"left": "...",  
   * &nbsp;&nbsp;"right": "..."  
   * &nbsp;}
   */
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Custom messages  
   *   
   * {  
   * &nbsp;multiple: string  
   *}
   */
  messages: PropTypes.object,
  /**
   * Whether to allow multiple options to be selected.  
   *   
   * true  
   * false
   */
  multiple: PropTypes.bool,
  /**
   * The name of the attribute when in a Form or FormField.  
   *   
   * "string"
   */
  name: PropTypes.string,
  /**
   * Function that will be called when the user selects an option.  
   *   
   * ({ value, option }) => {...}
   */
  onChange: PropTypes.func,
  /**
   * Function that will be called when the Select drop closes.  
   *   
   * () => {...}
   */
  onClose: PropTypes.func,
  /**
   * Use this to indicate that 'items' doesn't contain all that it could.  
   * It will be called when the entire list of items has been rendered.  
   * This might be used when the total number of items that could be retrieved is more than you'd want to load into the browser. 'onMore' allows you to lazily fetch more from the server only when needed.  
   *   
   * () => {...}
   */
  onMore: PropTypes.func,
  /**
   * Function that will be called when the Select drop opens.  
   *   
   * () => {...}
   */
  onOpen: PropTypes.func,
  /**
   * Function that will be called when the user types in the search input.  
   * If this property is not provided, no search field will be rendered.  
   *   
   * (string) => {...}
   */
  onSearch: PropTypes.func,
  /**
   * Control the state of the component.  
   *   
   * true  
   * false
   */
  open: PropTypes.bool,
  /**
   * Options can be either a string or an object.  
   * If an object is used, use children callback in order to render anything based on the current item.  
   *   
   * [  
   * &nbsp;"string"  
   * &nbsp;"number"  
   * &nbsp;"boolean"  
   * &nbsp;"element"  
   * &nbsp;"object"  
   * ]
   */
  options: PropTypes.array,
  /**
   * Placeholder to use when no value is provided.  
   *   
   * "string"  
   * "element"  
   * "node"
   */
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  /**
   * Whether this is a plain Select input with no border or padding.  
   *   
   * treu  
   * false
   */
  plain: PropTypes.bool,
  /**
   * Whether to replace previously rendered items with a generic spacing element when they have scrolled out of view.   
   * This is more performant but means that in-page searching will not find elements that have been replaced.  
   *   
   * **true**  
   * false
   */
  replace: PropTypes.bool,
  /**
   * Placeholder text to use in the search box when the search input is empty.  
   *   
   * "string"
   */
  searchPlaceholder: PropTypes.string,
  /**
   * Index of the currently selected option.  
   * When multiple, the set of options selected.  
   * NOTE: This is deprecated in favor of indicating the selected values via the 'value' property.  
   *   
   * number  
   * [  
   * &nbsp;number
   * ]
   */
  selected: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.array
  ]),
  /**
   * The size of the text and icon.  
   *   
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "string"
   */
  size: PropTypes.string,
  /**
   * Currently selected value.  
   * This can be an array when multiple.  
   * Passing an element allows the caller to control how the value is rendered.  
   * Passing an element is deprecated. Instead, use the 'valueLabel' property.  
   *   
   * "string"  
   * "element"  
   * "object"  
   * number  
   * [  
   * &nbsp;"string"  
   * &nbsp;"object"  
   * &nbsp;"number"  
   * ]
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array
  ]),
  /**
   * When the options array contains objects, this property indicates how to determine the value of each option.  
   * If a string is provided, it is used as the key to retrieve each option's value.  
   * If a function is provided, it is called with the option and the return value indicates the value.  
   * If reduce is true, the value coming via the key will be used for the onChange value and the value property is expected to be reduced to align.  
   *   
   * "string"  
   * "function"  
   * {  
   * &nbsp;key: "string,"  
   * &nbsp;reduce: boolean  
   * }
   */
  valueKey: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.object
  ]),
  /**
   * Provides custom rendering of the value. If not provided, Select will render the value automatically.  
   *   
   * "node"
   */
  valueLabel: PropTypes.object
}

export default Select
