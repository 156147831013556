/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Box from '../simple/box/Box'
import Divider from '../simple/divider/Divider'

import Text from '../simple/text/Text'

function WizardProgress (props) {
  const [step, setStep] = useState(props.step)
  useEffect(() => {
    setStep(props.step)
  }, [props.step])

  const [numSteps, setNumStep] = useState(props.numSteps)
  useEffect(() => {
    setNumStep(props.numSteps)
  }, [props.numSteps])

  const renderCircles = () => {

    var toRender = []

    var i = 0
    while (i < numSteps) {
      const isCurrentStep = i === step
      if (i !== 0) {
        toRender.push(<Divider flex='1' width='75px' height='2px' margin={{ top: '15px' }} />)
      }
      toRender.push(
        <Box
          border={{
            color: isCurrentStep ? 'white' : 'rgba(255,255,255,0.4)',
            size: isCurrentStep ? 'small' : 'xsmall'
          }}
          round='50%'
          width='30px'
          height='30px'
          alignItems='center'
        >
          <Text color={isCurrentStep ? 'white' : 'rgba(255,255,255,0.4)'} size='small' alignSelf='center' margin={{ top: '3px' }}>{i + 1}</Text>
        </Box>
      )
      i++
    }

    return (
      <Box
        direction='row'
      >
        {toRender}
      </Box>)
  }

  return (
    <Box>
      {renderCircles()}
    </Box>)
}

export default WizardProgress
