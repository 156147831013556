/* eslint-disable */
import React, { useState, useEffect } from "react";
import moment from "moment";

import Box from "../../simple/box/Box";
import Text from "../../simple/text/Text";
import FormField from "../../simple/formField/FormField";
import DateInput from "../../simple/input/DateInput";
import Select from "../../simple/input/Select";
import TextArea from "../../simple/input/TextArea";

// Resources
import { content } from "../../../resources/content";
import { settings } from "../../../config";
import OptionButtons from "../optionButtons/OptionButtons";
import Consent from "./Consent";

function Call(props) {
  const currentHour = moment().hour();
  const [nextSevenDays, setNextSevenDays] = useState([]);
  const [nextSevenDayOptions, setNextSevenDayOptions] = useState([]);
  const [formValues, setFormValues] = useState(props.formValues);

  const [timesToCall, setTimesToCall] = useState([
    "Anytime",
    "8am - 10am",
    "10am - 12pm",
    "12pm - 2pm",
    "2pm - 4pm",
    "4pm - 6pm",
  ]);
  useEffect(() => {
    if (formValues.dateToCall === "Today") {
      var newTimes = ["Anytime"];
      if (currentHour < 8) {
        newTimes.push("8am - 10am");
      }
      if (currentHour < 10) {
        newTimes.push("10am - 12pm");
      }
      if (currentHour < 12) {
        newTimes.push("12pm - 2pm");
      }
      if (currentHour < 14) {
        newTimes.push("2pm - 4pm");
      }
      if (currentHour < 16) {
        newTimes.push("4pm - 6pm");
      }
      setTimesToCall(newTimes);
    } else {
      setTimesToCall([
        "Anytime",
        "8am - 10am",
        "10am - 12pm",
        "12pm - 2pm",
        "2pm - 4pm",
        "4pm - 6pm",
      ]);
    }
  }, [formValues.dateToCall]);

  useEffect(() => {
    setFormValues(props.formValues);
  }, [props.formValues]);

  useEffect(() => {
    var datesToSet = [];
    var i = 0;
    while (i < 5) {
      var date;
      if (i === 0) {
        // date = moment().add(i, 'day').format('[Today], dddd MMM Do')
        // date = moment().add(i, 'day').format('[Today], dddd MMM Do')
        date = "Today";
      } else if (i === 1) {
        date = "Tomorrow";
      } else {
        date = moment().add(i, "day").format("dddd MMM Do");
      }

      // if it's after 6pm today, don't give the option
      if (currentHour > 18 && date === "Today") {
        i++;
        continue;
      }
      datesToSet.push(date);
      i++;
    }
    setNextSevenDays(datesToSet);

    var sevenDayOptions = [];
    for (const date of datesToSet) {
      sevenDayOptions.push({
        label: date,
        value: date,
      });
    }
    setNextSevenDayOptions(sevenDayOptions);
  }, []);

  const width = window.screen.width;
  return (
    <>
      <Box direction="row-responsive" gap="small">
        <FormField width="100%" name="whenToCall">
          <OptionButtons
            id='whenToCall'
            name='whenToCall'
            buttonBackground='altSecondary'
            buttonHover='secondary'
            buttonActive='secondary'
            buttonHeight='75px'
            buttonWidth={width < 444 ? "165px" : ""}
            wrap
            fontSize="xlarge"
            fontWeight="bold"
            style={{ flexDirection: "row", width: "100%" }}
            options={[
              {
                label: content.wizard.results.call_me_asap,
                value: content.wizard.results.call_me_asap
              },
              {
                label: content.wizard.results.arrange_a_callback,
                value: content.wizard.results.arrange_a_callback
              },
            ]}
          />
        </FormField>
      </Box>

      {formValues.whenToCall && formValues.whenToCall === content.wizard.results.arrange_a_callback && (
        <>
          <Text color="white" margin={{ top: "medium", bottom: "small" }}>
            {content.signup.best_label_lowercase}
          </Text>
          <Box width="100%">
            <Text color="white" weight="bold">
              {content.signup.field_labels.date}
            </Text>
          </Box>
          <Box direction="row-responsive" gap="small">
            <FormField
              width="100%"
              name="dateToCall"
              validate={() => props.validateFormField("dateToCall")}
            >
              <OptionButtons
                id="dateToCall"
                name="dateToCall"
                buttonBackground="altSecondary"
                buttonActive="secondary"
                buttonHeight="55px"
                buttonWidth={width < 444 ? "165px" : "130px"}
                width="100%"
                wrap
                fontSize="medium"
                fontWeight="normal"
                options={nextSevenDayOptions}
                styles={{
                  flexDirection: "row",
                }}
              />
            </FormField>
          </Box>
          {formValues.dateToCall && (
            <>
              <Box width="100%">
                <Text color="white" weight="bold">
                  {content.signup.field_labels.time}
                </Text>
              </Box>

              <Box direction="row-responsive" gap="small">
                <FormField
                  width="100%"
                  name="timeToCall"
                  validate={() => props.validateFormField("timeToCall")}
                  direction="row"
                >
                  <OptionButtons
                    id="timeToCall"
                    name="timeToCall"
                    buttonBackground="altSecondary"
                    buttonActive="secondary"
                    buttonHeight="55px"
                    buttonWidth={width < 444 ? "165px" : "130px"}
                    width="100%"
                    wrap
                    fontSize="medium"
                    fontWeight="normal"
                    styles={{
                      flexDirection: "row",
                    }}
                    options={timesToCall}
                  />
                </FormField>
              </Box>
            </>
          )}
        </>
      )}

      {formValues.whenToCall && (formValues.dateToCall || formValues.whenToCall === content.wizard.results.call_me_asap) && (
        <>
          <Box direction="row-responsive" gap="small">
            <FormField
              // label={<Box border={{ side: 'right', color: 'lightgrey', size: 'small' }} pad={{ right: 'xsmall' }}><Text color='white' weight='bold'>Any additional information that may help?</Text></Box>}
              name="notes"
              width="100%"
              direction="row"
              round="small"
              border={{ color: "white", size: "small" }}
              elevation="small"
              background={settings.darkMode ? "altSecondary" : "background"}
              margin={{ bottom: props.inputBottomMargin }}
              style={{ position: "relative" }}
              validate={() => props.validateFormField("notes")}
            >
              <TextArea
                id="notes"
                name="notes"
                direction="row"
                plain
                placeholder={content.signup.best_placeholder}
                width="large"
                focusIndicator={false}
                margin={{ left: "medium" }}
              />
            </FormField>
          </Box>
          <Consent validateFormField={props.validateFormField} />
        </>
      )}
    </>
  );
}

export default Call;
