/* eslint-disable */
export const regions = [
  {
    label: 'North East',
    value: 'NorthEast'
  },
  {
    label: 'North West',
    value: 'NorthWest'
  },
  {
    label: 'Yorkshire and the Humber',
    value: 'Yorkshire'
  },
  {
    label: 'East Midlands',
    value: 'EastMidlands'
  },
  {
    label: 'West Midlands',
    value: 'WestMidlands'
  },
  {
    label: 'East of England',
    value: 'EastEngland'
  },
  {
    label: 'London',
    value: 'London'
  },
  {
    label: 'South East',
    value: 'SouthEast'
  },
  {
    label: 'South West',
    value: 'SouthWest'
  }
]

export const sites = [
  {
    label: 'Site A',
    value: 'SiteA'
  },
  {
    label: 'Site B',
    value: 'SiteB'
  },
  {
    label: 'Site C',
    value: 'SiteC'
  },
  {
    label: 'Site D',
    value: 'SiteD'
  },
  {
    label: 'Site E',
    value: 'SiteE'
  },
]
