/* eslint-disable */
import React from 'react'

import Text from '../../simple/text/Text'
import H1 from '../../simple/heading/H1'

import Button from '../../simple/button/Button'

// Resources
import { content } from '../../../resources/content'
import Box from '../../simple/box/Box'

function PanelIntro (props) {
  return (
    <>

      <H1 color='white' weight='bold'>{content.wizard.intro.title}</H1>
      <Text size='medium' color='white' margin={{ bottom: 'medium' }} dangerouslySetInnerHTML={{ __html: content.wizard.intro.paragraph_1 }} />
      <Text size='medium' color='white' margin={{ bottom: 'medium' }} dangerouslySetInnerHTML={{ __html: content.wizard.intro.paragraph_2 }} />
      <Text size='medium' color='white' margin={{ bottom: 'medium' }} dangerouslySetInnerHTML={{ __html: content.wizard.intro.paragraph_3 }} />
      <Text size='medium' color='white' margin={{ bottom: 'medium' }} dangerouslySetInnerHTML={{ __html: content.wizard.intro.paragraph_4 }} />
      <Text size='medium' color='white' margin={{ bottom: 'large' }} dangerouslySetInnerHTML={{ __html: content.wizard.intro.paragraph_5 }} />

      <Box
        width='medium'
      >
        <Button
          label={content.wizard.controls.button_get_started}
          secondary
          onClick={() => props.nextStep()}
          fill
        />
      </Box>

    </>)
}

export default PanelIntro
