/* eslint no-debugger: "warn" */

// dependencies
import React from 'react'
import LocalStorageService from './LocalStorageService'

// setting
import { settings } from '../config'

const serverRoot = 'https://qs.e2ecommerce.uk'

const currentSite = LocalStorageService.get('activeSite')

class Api extends React.Component {
  get = async (apiToken, url, params = {}) => {
    // check for an api key, otherwise throw an error
    const options = {
      method: 'GET',
      mode: 'cors'
    }

    if (!params?.apiToken) {
      params.apiToken = apiToken
    }

    if (!params?.siteId) {
      params.siteId = currentSite?.id || '66268ec68c36dccb5ea1477f'
    }

    let paramsStr = ''
    if (params) {
      paramsStr = ''
      for (let key in params) {
        let value = params[key]
        if (typeof value === 'object') {
          value = JSON.stringify(value)
        }
        key = encodeURIComponent(key)
        value = encodeURIComponent(value)

        if (paramsStr === '') {
          paramsStr += '?'
        } else {
          paramsStr += '&'
        }
        paramsStr += `${key}=${value}`
      }
    }
    console.log(url)
    console.log(paramsStr)

    const request = new Request(`${serverRoot}${url}${paramsStr}`, options)
    const response = await fetch(request)
    return response.json()
  }

  post = async (apiToken, url, params = {}, options = {}) => {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')

    var optionsParams = {
      method: 'POST',
      mode: 'cors',
      headers,
      body: JSON.stringify(options)
    }

    if (!params.apiToken) {
      params.apiToken = apiToken
    }

    if (!params.siteId && currentSite?.id) {
      params.siteId = currentSite?.id || '66268ec68c36dccb5ea1477f'
    }

    let paramsStr = ''
    if (params) {
      paramsStr = ''
      for (let key in params) {
        let value = params[key]
        if (typeof value === 'object') {
          value = JSON.stringify(value)
        }
        key = encodeURIComponent(key)
        value = encodeURIComponent(value)

        if (paramsStr === '') {
          paramsStr += '?'
        } else {
          paramsStr += '&'
        }
        paramsStr += `${key}=${value}`
      }
    }

    const request = new Request(`${serverRoot}${url}${paramsStr}`, optionsParams)
    const response = await fetch(request)
    const returnResponse = await response.json()
    console.log('response ', returnResponse)
    return returnResponse
  }

  put = async (apiToken, url, params = {}, options = {}) => {
    if (!apiToken) {
      throw Error('Missing API Token')
    }
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    var optionsParams = {
      method: 'PUT',
      mode: 'cors',
      headers,
      body: JSON.stringify(options)
    }
    if (!params.apiToken) {
      params.apiToken = settings.apiToken
    }
    if (!params.siteId) {
      params.siteId = settings.siteId
    }
    let paramsStr = ''
    if (params) {
      paramsStr = ''
      for (let key in params) {
        let value = params[key]
        if (typeof value === 'object') {
          value = JSON.stringify(value)
        }
        key = encodeURIComponent(key)
        value = encodeURIComponent(value)
        if (paramsStr === '') {
          paramsStr += '?'
        } else {
          paramsStr += '&'
        }
        paramsStr += `${key}=${value}`
      }
    }
    const request = new Request(`${serverRoot}${url}${paramsStr}`, optionsParams)
    const response = await fetch(request)
    const returnResponse = await response.json()
    return returnResponse
  }

  sendDelete = async (apiToken, url, params = {}) => {
    // check for an api key, otherwise throw an error

    if (!apiToken) {
      throw Error('Missing API Token')
    }

    const options = {
      method: 'DELETE',
      mode: 'cors'
    }

    if (!params.apiToken) {
      params.apiToken = apiToken
    }

    if (!params.siteId) {
      params.siteId = currentSite?.id || '66268ec68c36dccb5ea1477f'
    }

    let paramsStr = ''
    if (params) {
      paramsStr = ''
      for (let key in params) {
        let value = params[key]
        if (typeof value === 'object') {
          value = JSON.stringify(value)
        }
        key = encodeURIComponent(key)
        value = encodeURIComponent(value)

        if (paramsStr === '') {
          paramsStr += '?'
        } else {
          paramsStr += '&'
        }
        paramsStr += `${key}=${value}`
      }
    }
    console.log(url)
    console.log(paramsStr)

    const request = new Request(`${serverRoot}${url}${paramsStr}`, options)
    const response = await fetch(request)
    return response.json()
  }
}

// export default Api
export default new Api()
