/* eslint-disable */
import React, { useState, useEffect } from 'react'
// 3rd Party
import { useHistory } from 'react-router-dom'

// Components
import Box from '../components/simple/box/Box.js'
import Text from '../components/simple/text/Text'
import CalculatorResults from '../components/compound/CalculatorResults.js'

// services
import NotificationService from '../services/NotificationService'

// Resources
import { content } from '../resources/content'

function WizardResults (props) {
  const history = useHistory()
  const [submissionId, setSubmissionId] = useState(history?.location?.data?.submissionId)
  const [uuid, setUuid] = useState(history?.location?.data?.uuid)
  const [debug] = useState(history?.location?.data?.debug)

  const [queryParam, setQueryParam] = useState({})
  const parseParams = (querystring) => {
    const params = new URLSearchParams(querystring)
    const obj = {}
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key)
      } else {
        obj[key] = params.get(key)
      }
    }
    return obj
  }
  useEffect(() => {
    const params = parseParams(window.location.search)
    setQueryParam(params)

    if (history?.location?.data?.uuid) {
      setUuid(history.location.data.uuid)
    }

    props.setIsSuccessPage(false)
    if (history?.location?.data?.submissionId) {
      setSubmissionId(history.location.data.submissionId)
    } else if (params?.debug || debug) {
      NotificationService.warning("You've accessed the temporary override for submission 1697")
      setSubmissionId(1697)
    } else {
      history.push('/')
    }
  }, [history.location.data])

  return (
    <Box
      gap='xsmall'
      margin={{ bottom: 'xlarge' }}
    >
      <Box
        gap='medium'
      >
        {submissionId && <CalculatorResults submissionId={submissionId} uuid={uuid} debug={debug || queryParam?.debug} />}
      </Box>
    </Box>
  )
}

export default WizardResults
