/* eslint-disable */
import React, { useState, useEffect } from "react";

// 3rd Party
import Nope from "nope-validator";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Dinero from "dinero.js";

// Components
import Box from "../components/simple/box/Box.js";
import Button from "../components/simple/button/Button";
import Form from "../components/simple/form/Form";
import FormField from "../components/simple/formField/FormField";
import Text from "../components/simple/text/Text";
import TextInput from "../components/simple/input/TextInput";
import OptionButtons from "../components/compound/optionButtons/OptionButtons";

// Resources
import { content } from "../resources/content";
import Call from "../components/compound/signup.js/Call.js";
import { settings } from "../config.js";

// Services
import LocalStorageService from "../services/LocalStorageService";
import NotificationService from "../services/NotificationService";
import FormService from "../services/FormService";
import Consent from "../components/compound/signup.js/Consent.js";

function Signup(props) {
  const apiToken = LocalStorageService.get("apiToken");
  const activeSite = LocalStorageService.get("activeSite");
  const currentUser = LocalStorageService.get("userDetails");

  const [formValues, setFormValues] = useState({
    type: "Purchaser",
  });
  const [numApplicants, setNumApplicants] = useState(1);
  const [showCall, setShowCall] = useState(false);
  const [showCalculator, setShowCalculator] = useState(false);
  const [buttonState, setButtonState] = useState();

  const history = useHistory();

  const FormSchema = Nope.object().shape({
    type: Nope.string().required().atLeast(2),
    site: Nope.string().required().atLeast(2),
    builder: Nope.string().required().atLeast(2),
    submittedBy: Nope.string().required().atLeast(2),
    plotNumber: Nope.string(),
    propertyPrice: Nope.string().required(),
    dateToCall: Nope.string().required(),
    timeToCall: Nope.string().required(),
    notes: Nope.string(),
    ...(buttonState === "submit"
      ? { acceptTC: Nope.string().required(content.acceptTC).atLeast(2) }
      : { acceptTC: Nope.string() }),
  });

  const ApplicantSchema = Nope.object().shape({
    name: Nope.string().required().atLeast(2),
    email: Nope.string().required().email(),
    contact: Nope.string()
      .required()
      .regex(
        /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/,
        "Please enter a valid number"
      ),
  });

  const validateFormField = (key) => {
    if (key === "acceptTC") {
      if (!formValues.acceptTC) {
        return {
          message: "You must consent to these terms to continue",
          status: "error",
        };
      }
    }

    // modify the date before it gets validated
    if (
      formValues.dateToCall &&
      formValues.timeToCall &&
      key === "timeToCall"
    ) {
      var time = "00:00";
      switch (formValues.timeToCall) {
        case "8am - 10am":
          time = "08:00";
          break;
        case "10am - 12pm":
          time = "10:00";
          break;
        case "12pm - 2pm":
          time = "12:00";
          break;
        case "2pm - 4pm":
          time = "14:00";
          break;
        case "4pm - 6pm":
          time = "16:00";
          break;
      }

      var dateFormat = "dddd MMM Do HH:mm";
      if (formValues?.dateToCall?.indexOf("Today") !== -1) {
        dateFormat = "[Today], dddd MMM Do HH:mm";
      } else if (formValues?.dateToCall?.includes("Tomorrow") !== -1) {
        dateFormat = "[Tomorrow], dddd MMM Do HH:mm";
      }
      var dateStr = `${formValues.dateToCall} ${time}`;
      if (moment(dateStr, dateFormat).isValid()) {
        // if the windows selected is before now, display an error
        if (moment().isAfter(moment(dateStr, dateFormat))) {
          return {
            message: "The time must be in the future",
            status: "error",
          };
        }
      }
    }

    const error = FormSchema.validate(formValues);
    // return an error
    if (error?.[key]) {
      return {
        message: error[key],
        status: "error",
      };
    }
  };

  const validateApplicantField = (applicant, key) => {
    const thisApplicant = {
      name: formValues[`app${applicant}Name`],
      email: formValues[`app${applicant}Email`],
      contact: formValues[`app${applicant}Contact`],
    };
    const error = ApplicantSchema.validate(thisApplicant);
    // return an error
    if (error?.[key]) {
      return {
        message: error[key],
        status: "error",
      };
    }
  };

  const onSubmit = async (event) => {
    var responses = [];
    const price = formValues.propertyPrice.replace(/\,/g, "");
    formValues.numApplicants = numApplicants;
    for (const key in formValues) {
      var value = formValues[key];
      if (key === "propertyPrice") {
        value = parseInt(price);
      }
      var item = {
        questionKey: key,
        questionText: key,
        response: value,
      };
      responses.push(item);
    }

    responses.push({
      questionKey: "loanAmount",
      questionText: "Loan Amount",
      response: parseInt(price) - (parseInt(price) * 5) / 100,
    });

    // mocking data for missing properties
    settings.requiredFQGeneralFields.map((item) => {
      if (
        formValues[item.questionKey] === undefined ||
        formValues[item.questionKey] === ""
      ) {
        responses.push(item);
      }
    });

    settings.requiredFQApplicantFields.map((item) => {
      for (let i = 1; i <= formValues.numApplicants; i++) {
        var key = "app" + i + item.questionKey;
        if (formValues[key] === undefined || formValues[key] === "") {
          var appMissing = {
            questionKey: key,
            questionText: item.questionText,
            response: item.response,
          };
          responses.push(appMissing);
        }
      }
    });

    // end mocking data for missing properties

    const data = {
      user: settings.userId,
      status: "Draft",
      type: "meridian-signupform",
      reference: "",
      responses: responses,
    };

    const params = {
      siteId: activeSite?.id || '66268ec68c36dccb5ea1477f',
    };

    console.log("this is what I'm about to submit", data.responses);

    var response = await FormService.makeSubmissions(
      settings.apiToken,
      data,
      params
    );
    if (response?.error) {
      NotificationService.error("There was an error saving this submission");
      return;
    }

    if (!response?.data?.submissionId) {
      NotificationService.error(
        "There was an error retrieving this submission"
      );
      return;
    }

    const submissionId = response.data.submissionId;

    if (buttonState === "calculator") {
      setShowCalculator(true);
      setShowCall(false);
      history.push({
        pathname: "/calculator",
        data: {
          formValues: formValues,
          submissionId: submissionId,
          responses: data.responses,
        },
      });
    }
    if (buttonState === "submit") {
      history.push("/success");
    }

    // reset form values
    setNumApplicants(1);
    var resetFormValues = {};
    for (const key of Object.keys(formValues)) {
      resetFormValues[key] = "";
    }
    setFormValues(resetFormValues);
  };

  const removeApplicant = () => {
    if (numApplicants > 1) {
      setNumApplicants(numApplicants - 1);
    }
  };

  const addApplicant = () => {
    if (numApplicants < 4) {
      setNumApplicants(numApplicants + 1);
    }
  };

  useEffect(() => {
    props.setIsSuccessPage(false);
  }, []);

  const renderApplicants = () => {
    var toReturn = [];
    var i = 1;
    while (i <= numApplicants) {
      const thisI = i;
      toReturn.push(
        <div key={i}>
          <Text
            color="white"
            size="medium"
            weight="bold"
            margin={{ top: "small", bottom: "small" }}
          >
            {content.signup.applicant_label} {i}
          </Text>
          <Box>
            <FormField
              label={
                <Box
                  width="80px"
                  border={{ side: "right", color: "lightgrey", size: "small" }}
                  pad={{ right: "xsmall" }}
                >
                  <Text color="white" weight="bold" size={labelFontSize}>
                    {content.signup.field_labels.applicants.name}
                  </Text>
                </Box>
              }
              name={`app${i}Name`}
              width="100%"
              validate={() => validateApplicantField(thisI, "name")}
              direction="row"
              round="small"
              border={{ color: "white", size: "small" }}
              elevation="small"
              background="background"
              margin={{ bottom: inputBottomMargin }}
              style={{ position: "relative" }}
            >
              <TextInput
                id={`app${i}Name`}
                name={`app${i}Name`}
                plain
                width="large"
                focusIndicator={false}
                margin={{ left: "medium" }}
              />
            </FormField>
            <FormField
              label={
                <Box
                  width="80px"
                  border={{ side: "right", color: "lightgrey", size: "small" }}
                  pad={{ right: "xsmall" }}
                >
                  <Text color="white" weight="bold" size={labelFontSize}>
                    {content.signup.field_labels.applicants.email}
                  </Text>
                </Box>
              }
              name={`app${i}Email`}
              width="100%"
              validate={() => validateApplicantField(thisI, "email")}
              direction="row"
              round="small"
              border={{ color: "white", size: "small" }}
              elevation="small"
              background="background"
              margin={{ bottom: inputBottomMargin }}
              style={{ position: "relative" }}
            >
              <TextInput
                id={`app${i}Email`}
                name={`app${i}Email`}
                plain
                type="email"
                width="large"
                focusIndicator={false}
                margin={{ left: "medium" }}
              />
            </FormField>
          </Box>

          <Box direction="row-responsive" gap="small">
            <FormField
              label={
                <Box
                  width="80px"
                  border={{ side: "right", color: "lightgrey", size: "small" }}
                  pad={{ right: "xsmall" }}
                >
                  <Text color="white" weight="bold" size={labelFontSize}>
                    {content.signup.field_labels.applicants.phone}
                  </Text>
                </Box>
              }
              name={`app${i}Contact`}
              width="100%"
              validate={() => validateApplicantField(thisI, "contact")}
              direction="row"
              round="small"
              border={{ color: "white", size: "small" }}
              elevation="small"
              background="background"
              margin={{ bottom: inputBottomMargin }}
              style={{ position: "relative" }}
            >
              <TextInput
                id={`app${i}Contact`}
                name={`app${i}Contact`}
                plain
                width="large"
                focusIndicator={false}
                margin={{ left: "medium" }}
              />
            </FormField>
          </Box>
        </div>
      );
      i++;
    }
    return toReturn;
  };

  var inputBottomMargin = "medium";
  var inputTwoColumnWidth = "50%";
  var optionButtonFontSize = "medium";
  var labelFontSize = "medium";

  var imWithLabelWidth = "200px";

  if (props.window.width < 1024) {
    imWithLabelWidth = "90px";
  }

  if (props.window.width < 840) {
    inputBottomMargin = "large";
    inputTwoColumnWidth = "100%";
  }

  if (props.window.width < 500) {
    optionButtonFontSize = "small";
    labelFontSize = "small";
    imWithLabelWidth = "70px";
  }

  if (props.window.width < 400) {
    optionButtonFontSize = "xsmall";
  }

  return (
    <>
      <Form
        value={formValues}
        validate="blur"
        onChange={(nextValue) => setFormValues(nextValue)}
        onSubmit={() => onSubmit()}
      >
        <Box gap="xsmall">
          <Box>
            <FormField
              width="100%"
              label={
                <Box
                  width={imWithLabelWidth}
                  border={{ side: "right", color: "lightgrey", size: "small" }}
                  pad={{ right: "xsmall" }}
                >
                  <Text color="white" weight="bold" size={labelFontSize}>
                    {content.signup.field_labels.type}
                  </Text>
                </Box>
              }
              name="type"
              validate={() => validateFormField("type")}
              direction="row"
              round="small"
              border={{ color: "white", size: "small" }}
              elevation="small"
              background="background"
              margin={{ bottom: inputBottomMargin }}
              style={{ position: "relative" }}
            >
              <OptionButtons
                id="type"
                name="type"
                fontSize={optionButtonFontSize}
                options={content.signup.field_labels.type_options}
              />
            </FormField>

            {formValues?.type === "Sales Negotiator" && (
              <FormField
                width="100%"
                label={
                  <Box
                    width={props.window.width < 500 ? "120px" : "200px"}
                    border={{
                      side: "right",
                      color: "lightgrey",
                      size: "small",
                    }}
                    pad={{ right: "xsmall" }}
                  >
                    <Text color="white" weight="bold" size={labelFontSize}>
                      {content.signup.field_labels.submitted_by}
                    </Text>
                  </Box>
                }
                name="submittedBy"
                validate={() => validateFormField("submittedBy")}
                direction="row"
                round="small"
                border={{ color: "white", size: "small" }}
                elevation="small"
                background="background"
                margin={{ bottom: inputBottomMargin }}
                style={{ position: "relative" }}
              >
                <TextInput
                  id="submittedBy"
                  name="submittedBy"
                  plain
                  width="large"
                  focusIndicator={false}
                  margin={{ left: "medium" }}
                />
              </FormField>
            )}

            {formValues?.type !== "Sales Negotiator" && (
              <Text
                color="white"
                size="medium"
                weight="bold"
                margin={{ top: "medium", bottom: "small" }}
              >
                {content.signup.interested_label}
              </Text>
            )}
            {formValues?.type === "Sales Negotiator" && (
              <FormField
                width="100%"
                label={
                  <Box
                    width={props.window.width < 500 ? "120px" : "200px"}
                    border={{
                      side: "right",
                      color: "lightgrey",
                      size: "small",
                    }}
                    pad={{ right: "xsmall" }}
                  >
                    <Text color="white" weight="bold" size={labelFontSize}>
                      {content.signup.field_labels.builder}{" "}
                    </Text>
                  </Box>
                }
                name="builder"
                validate={() => validateFormField("builder")}
                direction="row"
                round="small"
                border={{ color: "white", size: "small" }}
                elevation="small"
                background="background"
                margin={{ bottom: inputBottomMargin }}
                style={{ position: "relative" }}
              >
                <TextInput
                  id="builder"
                  name="builder"
                  plain
                  width="large"
                  focusIndicator={false}
                  margin={{ left: "medium" }}
                />
              </FormField>
            )}

            <FormField
              width="100%"
              label={
                <Box
                  width={props.window.width < 500 ? "120px" : "200px"}
                  border={{ side: "right", color: "lightgrey", size: "small" }}
                  pad={{ right: "xsmall" }}
                >
                  <Text color="white" weight="bold" size={labelFontSize}>
                    {formValues?.type === "Sales Negotiator"
                      ? content.signup.field_labels.site
                      : content.signup.field_labels.site_development}
                  </Text>
                </Box>
              }
              name="site"
              validate={() => validateFormField("site")}
              direction="row"
              round="small"
              border={{ color: "white", size: "small" }}
              elevation="small"
              background="background"
              margin={{ bottom: inputBottomMargin }}
              style={{ position: "relative" }}
            >
              <TextInput
                id="site"
                name="site"
                plain
                width="large"
                focusIndicator={false}
                margin={{ left: "medium" }}
              />
            </FormField>
          </Box>

          {renderApplicants()}

          <Box direction="row-responsive" gap="small">
            {numApplicants < 4 && (
              <FormField round="small" elevation="small">
                <Button
                  secondary
                  elevation="small"
                  round="large"
                  label={
                    <Box>
                      <Text color="white" weight="bold" size={labelFontSize}>
                        {content.signup.button_label_add}
                      </Text>
                    </Box>
                  }
                  onClick={() => {
                    addApplicant();
                  }}
                />
              </FormField>
            )}

            {numApplicants > 1 && (
              <FormField round="small" elevation="small">
                <Button
                  elevation="small"
                  secondary
                  label={
                    <Box>
                      <Text color="white" weight="bold" size={labelFontSize}>
                        {content.signup.button_label_remove}
                      </Text>
                    </Box>
                  }
                  onClick={() => {
                    removeApplicant();
                  }}
                />
              </FormField>
            )}
          </Box>

          <Text
            color="white"
            size="medium"
            weight="bold"
            margin={{ top: "medium", bottom: "small" }}
          >
            {content.signup.property_label}
          </Text>

          <Box gap="small">
            <FormField
              label={
                <Box
                  width={props.window.width < 500 ? "145px" : "190px"}
                  border={{ side: "right", color: "lightgrey", size: "small" }}
                  pad={{ right: "xsmall" }}
                >
                  <Text color="white" weight="bold" size={labelFontSize}>
                    {content.signup.field_labels.plot}
                  </Text>
                </Box>
              }
              name="plotNumber"
              // width={inputTwoColumnWidth}
              validate={() => validateFormField("plotNumber")}
              direction="row"
              round="small"
              width="100%"
              border={{ color: "white", size: "small" }}
              elevation="small"
              background="background"
              margin={{ bottom: inputBottomMargin }}
              style={{ position: "relative" }}
            >
              <TextInput
                id="plotNumber"
                name="plotNumber"
                plain
                width="large"
                focusIndicator={false}
                margin={{ left: "medium" }}
              />
            </FormField>
            <FormField
              label={
                <Box
                  width={props.window.width < 500 ? "145px" : "190px"}
                  border={{ side: "right", color: "lightgrey", size: "small" }}
                  pad={{ right: "xsmall" }}
                >
                  <Text color="white" weight="bold" size={labelFontSize}>
                    {content.signup.field_labels.purchase}
                  </Text>
                </Box>
              }
              name="propertyPrice"
              validate={() => validateFormField("propertyPrice")}
              direction="row"
              round="small"
              width="100%"
              border={{ color: "white", size: "small" }}
              elevation="small"
              background="background"
              margin={{ bottom: inputBottomMargin }}
              style={{ position: "relative" }}
            >
              <TextInput
                id="propertyPrice"
                name="propertyPrice"
                plain
                width="large"
                focusIndicator={false}
                margin={{ left: "medium" }}
                onChange={(event) => {
                  var theseFormValues = formValues;
                  // format the date
                  var amount = event.target.value
                    ? parseInt(event.target.value.match(/\d+/gi).join(""))
                    : 0;
                  theseFormValues.propertyPrice = Dinero({
                    amount,
                    currency: "GBP",
                    precision: 0,
                  }).toFormat("0,0");
                  setFormValues(theseFormValues);
                }}
              />
            </FormField>
          </Box>
          <Text
            color="white"
            size="medium"
            weight="bold"
            margin={{ top: "medium", bottom: "small" }}
          >
            {content.signup.calculator_label}
          </Text>
          <Box
            direction="row-responsive"
            margin={{ top: "small", bottom: "medium" }}
            gap="small"
          >
            <FormField name="calculator" round="small" elevation="small">
              <Button
                type="submit"
                onClick={() => setButtonState("calculator")}
                label={content.signup.take_a_look_button_label}
                secondary
                style={{ opacity: showCall ? ".5" : "" }}
              />
            </FormField>
            <FormField
              name="call"
              round="small"
              elevation="small"
              onClick={() => {
                setShowCall(true);
                setShowCalculator(false);
              }}
            >
              <Button
                label={content.signup.call_button_label}
                secondary
                style={{ opacity: showCalculator ? ".5" : "" }}
              />
            </FormField>
          </Box>
          {showCall && (
            <Call
              validateFormField={validateFormField}
              inputTwoColumnWidth={inputTwoColumnWidth}
              inputBottomMargin={inputBottomMargin}
            />
          )}
          {showCall && (
            <>
              <Consent
                validateFormField={validateFormField}
                formValues={formValues}
              />
              <Box
                direction="row-responsive"
                margin={{ top: "small", bottom: "medium" }}
              >
                <FormField
                  htmlFor="submit"
                  name="submit"
                  round="small"
                  elevation="small"
                >
                  <Button
                    label={content.signup.submit_button_label}
                    secondary
                    type="submit"
                    onClick={() => setButtonState("submit")}
                  />
                </FormField>
              </Box>
              <Box
                background="background"
                round="small"
                pad="small"
                gap="small"
              >
                <Text color="white" size="small">
                  {content.signup.caveat_1}
                </Text>
                <Text color="white" size="small">
                  {content.signup.caveat_2}
                </Text>
              </Box>
            </>
          )}
        </Box>
      </Form>
    </>
  );
}

export default Signup;
