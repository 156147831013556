/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { TextArea as GTextArea } from 'grommet'

/**
 * `TextArea` renders a Grommet TextArea box
 */
function TextArea (props) {
  // Auto scroll Notes textarea
  const getScrollHeight = (elm) => {
    var savedValue = elm.value
    elm.value = ''
    elm._baseScrollHeight = elm.scrollHeight
    elm.value = savedValue
  }

  const onExpandableTextareaInput = ({ target: elm }) => {
    // make sure the input event originated from a textarea and it's desired to be auto-expandable
    if (!elm.classList.contains('autoExpand') || !elm.nodeName === 'TEXTAREA') return

    var minRows = elm.getAttribute('data-min-rows') | 0
    var rows
    !elm._baseScrollHeight && getScrollHeight(elm)

    elm.rows = minRows
    rows = Math.ceil((elm.scrollHeight - elm._baseScrollHeight) / 16)
    elm.rows = minRows + rows
  }

  document.addEventListener('input', onExpandableTextareaInput)

  return (
    <GTextArea
      {...props}
      // onChange={(input) => onChange(input)}
    />)
}

TextArea.propTypes = {
  /**
   * Custom title to be used by screen readers.  
   *   
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * Add a className of 'auotExpand' to auto expand
   * the TextArea while typing
   */
  className: PropTypes.string,
  /**
   * Whether the width and height should fill the container.  
   *  
   * true
   * **false**
   */
  fill: PropTypes.bool,
  /**
   * Whether the plain text input should receive a focus outline.  
   */
  focusIndicator: PropTypes.bool,
  /**
   * The id attribute of the input.
   */
  id: PropTypes.string,
  /**
   * The name of the attribute when in a Form or FormField.
   */
  name: PropTypes.string,
  /**
   * Function that will be called when the user types in the input.
   */
  onChange: PropTypes.func,
  /**
   * Placeholder to use when no value is provided.  
   *   
   * "string"
   */
  placeholder: PropTypes.string,
  /**
   * Whether this is a plain input with no border or padding.  
   * Only use this when the containing context provides sufficient affordance  
   *   
   * true  
   * false
   */
  plain: PropTypes.bool,
  /**
   * Whether user is allowed to resize the textarea.  
   *   
   * vertical  
   * horizontal  
   * **true**  
   * false
   */
  resize: PropTypes.bool,
  /**
   * The size of the TextArea.  
   *   
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "string"
   */
  size: PropTypes.string,
  /**
   * What text to put in the input.
   */
  value: PropTypes.string
}

export default TextArea
