/* eslint-disable */
import React, { useState, useEffect } from 'react'

// 3rd Party
import Nope from 'nope-validator'
import { useHistory } from 'react-router-dom'
import PulseLoader from "react-spinners/PulseLoader";

// Components
import Box from '../components/simple/box/Box.js'
import Button from '../components/simple/button/Button'
import Form from '../components/simple/form/Form'
import FormField from '../components/simple/formField/FormField'
import Text from '../components/simple/text/Text'
import TextInput from '../components/simple/input/TextInput'
import OptionButtons from '../components/compound/optionButtons/OptionButtons'
import Select from '../components/simple/input/Select'

// Resources
import { content } from '../resources/content'
import { settings } from '../config.js'
import { regions } from '../utils/builders.js'

// Services
import LocalStorageService from '../services/LocalStorageService'
import FormService from '../services/FormService'
import NotificationService from '../services/NotificationService'

function Calculator (props) {
  var inputBottomMargin = 'medium'
  var inputTwoColumnWidth = '50%'
  if (props.window.width < 840) {
    inputBottomMargin = 'large'
    inputTwoColumnWidth = '100%'
  }

  const apiToken = LocalStorageService.get('apiToken')

  const history = useHistory()
  const [formValuesCalculator, setFormValuesCalculator] = useState()
  const [formResponses, setFormResponses] = useState(history.location.data.responses)

  const [showSpinner, setShowSpinner] = useState(false)

  const FormSchema = Nope.object().shape({
    firstTimeBuyer: Nope.string().required(),
    secondHome: Nope.string().required(),
    deposit: Nope.number().required(),
    termYears: Nope.number().required(),
    location: Nope.string()
  })

  const ApplicantSchema = Nope.object().shape({
    income: Nope.number().required(),
    otherIncome: Nope.string()
  })

  const validateFormField = (key) => {
    const error = FormSchema.validate(formValuesCalculator)

    // return an error
    if (error?.[key]) {
      return {
        message: error[key],
        status: 'error'
      }
    }
  }

  const validateApplicantField = (applicant, key) => {
    const thisApplicant = {
      income: formValuesCalculator[`app${applicant}Income`],
      otherIncome: formValuesCalculator[`app${applicant}OtherIncome`]
    }
    const error = ApplicantSchema.validate(thisApplicant)
    // return an error
    if (error?.[key]) {
      return {
        message: error[key],
        status: 'error'
      }
    }
  }

  const onSubmit = async () => {
    setShowSpinner(true)
    var responses = []
    var item = {
      key: '',
      questionText: '',
      answer: ''
    }

    for (let i = 1; i <= history.location.data.formValues.numApplicants; i++) {
      var key = 'app' + i + 'Salary'
      var k1 = 'app' + i + 'Income'
      var k2 = 'app' + i + 'OtherIncome'
      const salary = parseInt(formValuesCalculator[k1]) + parseInt(formValuesCalculator[k2])
      item = {
        questionKey: key,
        questionText: 'Salary',
        response: String(salary)
      }
      responses.push(item)
    }
    for (let i = 0; i < formResponses.length; i++) {
      item = {
        questionKey: formResponses[i].questionKey,
        questionText: formResponses[i].questionText,
        response: formResponses[i].response
      }
      responses.push(item)
    }

    for (const key in formValuesCalculator) {
      var value = formValuesCalculator[key]
      item = {
        questionKey: key,
        questionText: key,
        response: value
      }
      responses.push(item)
    }

    const data = {
      user: settings.userId,
      status: 'Draft',
      type: 'fqform',
      reference: '',
      responses: responses
    }

    var response = await FormService.updateSubmission('eeae9eb2-be99-43ac-b922-6efd7168eb7b', history.location.data.submissionId, data)

    if (response.error) {
      NotificationService.error('There was an error saving this submission')
      setShowSpinner(false)
    } else {
      try {
        var landg = await FormService.getLandg(settings.apiToken, history.location.data.submissionId)
      } catch (err) {
        console.error(err)
      }

      if (landg?.error) {
        console.log(landg?.error)
        NotificationService.error('There was an error retrieving this submission')
        setShowSpinner(false)
        return
      }

      if (landg?.data?.result?.quotes) {
        history.push({
          pathname: '/calculator-result',
          data: { formValuesCalculator: formValuesCalculator, formValues: history.location.data.formValues, submissionId: history.location.data.submissionId, inputBottomMargin: inputBottomMargin, inputTwoColumnWidth: inputTwoColumnWidth, landgResults: landg.data.result.quotes }
        })
      }
    }
  }

  const renderApplicants = () => {
    var toReturn = []
    var i = 1
    while (i <= history.location.data.formValues.numApplicants) {
      const thisI = i
      toReturn.push(
        <div key={i}>
          <Text color='white' size='medium' weight='bold' margin={{ top: 'small', bottom: 'small' }}>{content.calculator.applicant_label} {i}</Text>
          <Box>
            <Text style={{ paddingLeft: '20px', paddingBottom: '10px' }} color='white' weight='bold'>{content.calculator.field_labels.applicants.income}</Text>
            <FormField
              label={<Box pad={{ right: 'xsmall' }}><Text color='white' weight='bold'>£</Text></Box>}
              name={`app${i}Income`}
              width='100%'
              validate={() => validateApplicantField(thisI, 'income')}
              direction='row'
              round='small'
              border={{ color: 'white', size: 'small' }}
              elevation='small'
              background='background'
              margin={{ bottom: inputBottomMargin }}
              style={{ position: 'relative' }}
            >
              <TextInput
                id={`app${i}Income`}
                name={`app${i}Income`}
                plain
                width='large'
                focusIndicator={false}
                margin={{ left: 'medium' }}
              />
            </FormField>
            <Text style={{ paddingLeft: '20px', paddingBottom: '10px' }} color='white' weight='bold'>{content.calculator.field_labels.applicants.otherIncome}</Text>
            <FormField
              label={<Box pad={{ right: 'xsmall' }}><Text color='white' weight='bold'>£</Text></Box>}
              name={`app${i}OtherIncome`}
              width='100%'
              validate={() => validateApplicantField(thisI, 'otherIncome')}
              direction='row'
              round='small'
              border={{ color: 'white', size: 'small' }}
              elevation='small'
              background='background'
              margin={{ bottom: inputBottomMargin }}
              style={{ position: 'relative' }}
            >
              <TextInput
                id={`app${i}OtherIncome`}
                name={`app${i}OtherIncome`}
                plain
                width='large'
                focusIndicator={false}
                margin={{ left: 'medium' }}
              />
            </FormField>
          </Box>
        </div>
      )
      i++
    }
    return toReturn
  }

  const getDetailsText = () => {
    var originalText = content.calculator.details_label
    var Obj = {
      development: props?.location?.data?.formValues?.site,
      purchasePrice: props?.location?.data?.formValues?.propertyPrice
    }

    return originalText.replace(/development|purchasePrice/gi, function (matched) {
      return Obj[matched]
    })
  }

  useEffect(() => {
    const price = history.location.data.formValues.propertyPrice.replace(/\,/g,'')
    props.setIsSuccessPage(false)
    const defaultFormValues = {
      firstTimeBuyer: 'Yes',
      secondHome: 'No',
      deposit: (parseInt(price) * 5) / 100,
      termYears: '30'
    }
    setFormValuesCalculator(defaultFormValues)
  }, [])

  return (
    <>
      <Text color='white' size='large' margin={{ top: 'medium', bottom: 'small' }}>{content.calculator.title}</Text>
      <Form
        value={formValuesCalculator}
        validate='blur'
        onChange={(nextValue) => setFormValuesCalculator(nextValue)}
        onSubmit={() => onSubmit()}
      >
        <Box
          gap='xsmall'
        >
          {renderApplicants()}
        </Box>
        <Text color='white' size='large' margin={{ top: 'medium', bottom: 'small' }}>{getDetailsText()}</Text>
        <Box
          gap='xsmall'
        >
          <Text color='white' size='medium' weight='bold' margin={{ top: 'small', bottom: 'small' }}>{content.calculator.field_labels.first_buyer_label}</Text>
        </Box>
        <Box direction='row-responsive' gap='small'>
          <FormField
            validate={() => validateFormField('firstTimeBuyer')}
            htmlFor='firstTimeBuyer'
            name='firstTimeBuyer'
          >
            <OptionButtons
              id='firstTimeBuyer'
              name='firstTimeBuyer'
            />
          </FormField>
        </Box>
        <Box
          gap='xsmall'
        >
          <Text color='white' size='medium' weight='bold' margin={{ top: 'small', bottom: 'small' }}>{content.calculator.field_labels.second_home_label}</Text>
        </Box>
        <Box direction='row-responsive' gap='small'>
          <FormField
            validate={() => validateFormField('secondHome')}
            htmlFor='secondHome'
            name='secondHome'
          >
            <OptionButtons
              id='secondHome'
              name='secondHome'
            />
          </FormField>
        </Box>
        <Box
          gap='xsmall'
        >
          <Text color='white' size='medium' weight='bold' margin={{ top: 'small', bottom: 'small' }}>{content.calculator.field_labels.where_label}</Text>
          <Box>
            <FormField
              htmlFor='location'
              name='location'
              width='100%'
              validate={() => validateApplicantField('location')}
              direction='row'
              round='small'
              border={{ color: 'white', size: 'small' }}
              elevation='small'
              background='background'
              margin={{ bottom: inputBottomMargin }}
              style={{ position: 'relative' }}
            >
              <Select
                plain
                name='location'
                emptySearchMessage='No values found'
                labelKey='label'
                options={regions}
                valueKey={{ key: 'value', reduce: true }}
              />
            </FormField>
          </Box>
          <Text color='white' size='medium' weight='bold' margin={{ top: 'small', bottom: 'small' }}>{content.calculator.field_labels.deposit_label}</Text>
          <Box>
            <FormField
              label={<Box pad={{ right: 'xsmall' }}><Text color='white' weight='bold'>£</Text></Box>}
              name='deposit'
              width='100%'
              validate={() => validateApplicantField('deposit')}
              direction='row'
              round='small'
              border={{ color: 'white', size: 'small' }}
              elevation='small'
              background='background'
              margin={{ bottom: inputBottomMargin }}
              style={{ position: 'relative' }}
            >
              <TextInput
                id='deposit'
                name='deposit'
                plain
                width='large'
                focusIndicator={false}
                margin={{ left: 'medium' }}
              />
            </FormField>
          </Box>
          <Text color='white' size='medium' weight='bold' margin={{ top: 'small', bottom: 'small' }}>{content.calculator.field_labels.term_label}</Text>
          <Box>
            <FormField
              name='termYears'
              width='100%'
              validate={() => validateApplicantField('termYears')}
              direction='row'
              round='small'
              border={{ color: 'white', size: 'small' }}
              elevation='small'
              background='background'
              margin={{ bottom: inputBottomMargin }}
              style={{ position: 'relative' }}
            >
              <TextInput
                id='termYears'
                name='termYears'
                plain
                width='large'
                focusIndicator={false}
                margin={{ left: 'medium' }}
              />
            </FormField>
          </Box>
        </Box>
        <Box direction='row-responsive' margin={{ top: 'small', bottom: 'medium' }}>
          <Button
            label={(showSpinner ? <PulseLoader color='#fff' /> : content.calculator.submit_button_label)}
            secondary
            type='submit'
            fill
          />
        </Box>
      </Form>
    </>
  )
}

export default Calculator
