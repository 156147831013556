/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { Button as GButton } from 'grommet'

/**
 * `Button` renders a Grommet Button
 */
class Button extends React.Component {
  render () {
    // const handleClick = () => this.props.onClick()

    return (
      <GButton
        className={this.props.className}
        {...this.props}
        // onClick={() => handleClick()}
      />)
  }
}

Button.propTypes = {
  /**
   * Custom label to be used by screen readers. When provided, an aria-label will be added to the element.
   *
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * Whether the button is active.
   *
   * true
   * **false**
   */
  active: PropTypes.bool,
  /**
   * How to align along the cross axis when contained in a Box or along the column axis when contained in a Grid.
   *
   *  "start"
   *  "end"
   *  "center"
   *  "stretch"
   */
  alignSelf: PropTypes.string,
  /**
   * The DOM tag or react component to use for the element.
   *
   * "string"
   * function
   */
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  /**
   * Fill color for primary, label color for plain, border color otherwise.
   *
   * "control"
   */
  color: PropTypes.string,
  /**
   * Whether the button is disabled.
   *
   * true
   * **false**
   */
  disabled: PropTypes.bool,
  /**
   * Whether the button expands to fill all of the available width and/or height.
   *
   * "horizontal"
   * "vertical"
   * true
   * **false**
   */
  fill: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  /**
   * Whether when 'plain' it should receive a focus outline.
   *
   * **true**
   * false
   */
  focusIndicator: PropTypes.bool,
  /**
   * The amount of spacing between icon and label in the button.
   *
   *  "none"
   *  "xxsmall"
   *  "xsmall"
   *  **"small"**
   *  "medium"
   *  "large"
   *  "xlarge"
   */
  gap: PropTypes.string,
  /**
   * The name of the area to place this inside a parent Grid.
   *
   * "a parent grid area name"
   */
  gridArea: PropTypes.string,
  /**
   * The hover indicator to apply when the user is mousing over the button.
   * An object can be also be specified for color index support: {background: 'neutral-2'}. This prop is meant to be used only with plain Buttons.
   *
   *  - true
   *  - **false**
   *  - "string"
   *  - "background"
   *  - {
   *  - &nbsp;color: "string,"
   *  - &nbsp;dark:
   *  - &nbsp;&nbsp;"boolean"
   *  - &nbsp;&nbsp;"string,"
   *  - &nbsp;image: "string,"
   *  - &nbsp;light: "string,"
   *  - &nbsp;position: "string,"
   *  - &nbsp;opacity:
   *  - &nbsp;&nbsp;"string"
   *  - &nbsp;&nbsp;"boolean"
   *  - &nbsp;&nbsp;"number"
   *  - &nbsp;&nbsp;"weak"
   *  - &nbsp;&nbsp;"medium"
   *  - &nbsp;&nbsp;"strong,"
   *  - &nbsp;repeat:
   *  - &nbsp;&nbsp;"no-repeat"
   *  - &nbsp;&nbsp;"repeat"
   *  - &nbsp;&nbsp;"string,"
   *  - &nbsp;size:
   *  - &nbsp;&nbsp;"cover"
   *  - &nbsp;&nbsp;"contain"
   *  - &nbsp;&nbsp;"string"
   *  - }
   */
  hoverIndicator: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object
  ]),
  /**
   * If specified, the button will behave like an anchor tag.
   *
   * "//my.com/path"
   */
  href: PropTypes.string,
  /**
   * Icon element to place in the button.
   *
   * eg. "\<Add \/>""
   */
  icon: PropTypes.object,
  /**
   * Label text to place in the button.
   *
   * "Add"
   * \<Box>...\</Box>
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The amount of margin around the component. An object can be specified to distinguish horizontal margin, vertical margin, and margin on a particular side.
   *
   * "xsmall"
   * "small"
   * "medium"
   * "large"
   * "xlarge"
   * &nbsp;{
   * &nbsp;&nbsp;"vertical": "...",
   * &nbsp;&nbsp;"horizontal": "...",
   * &nbsp;&nbsp;"top": "...",
   * &nbsp;&nbsp;"bottom": "...",
   * &nbsp;&nbsp;"left": "...",
   * &nbsp;&nbsp;"right": "..."
   * &nbsp;}
   */
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Click handler. Not setting this property and not specifying a href causes the Button to be disabled.
   *
   * () => {}
   */
  onClick: PropTypes.func,
  /**
   * Whether this is a plain button with no border or pad. Non plain button will show both pad and border. The plain button has no border and unless the icon prop exist it has no pad as well.
   *
   * true
   * **false**
   */
  plain: PropTypes.bool,
  /**
   * Whether this is a primary button. There should be at most one per page or screen.
   *
   * true
   * **false**
   */
  primary: PropTypes.bool,
  /**
   * Whether an icon and label should be reversed so that the icon is at the end of the anchor.
   *
   * true
   * **false**
   */
  reverse: PropTypes.bool,
  /**
   * Whether this is a secondary button.
   */
  secondary: PropTypes.bool,
  /**
   * The possible sizes of Button, that impacts the overall Button padding, border radius, text size and line height. 'size' will not impact any icon related sizing.
   *
   * "small"
   * "medium"
   * "large"
   */
  size: PropTypes.string,
  /**
   * Specifies where to display the URL defined in the href property.
   *
   * "_self"
   * "_blank"
   * "_parent"
   * "_top"
   * "string"
   */
  target: PropTypes.string,
  /**
   * The type of button. Set the type to submit for the default button on forms.
   *
   * **"button"**
   * "reset"
   * "submit"
   */
  type: PropTypes.string
}

export default Button
