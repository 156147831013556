/* eslint-disable */
import { deepMerge } from 'grommet/utils'

// Default theme
import { theme } from './theme'

export const projectThemeDark = deepMerge(theme, {
  // Global Settings
  global: {
    font: {
      family: 'brother-1816, sans-serif'
    },
    elevation: {
      standard: {
        none: 'none',
        // You can override the values for box-shadow here.
        xsmall: '0px 2px 2px rgba(255, 255, 255, 0.40)',
        small: '0px 4px 4px rgba(255, 255, 255, 0.40)',
        medium: '0px 6px 8px rgba(255, 255, 255, 0.40)',
        large: '0px 8px 16px rgba(255, 255, 255, 0.40)',
        xlarge: '0px 12px 24px rgba(255, 255, 255, 0.40)',
      }
    },
    colors: {
      brand: '#43667d',
      primary: '#43667d',
      primaryDark: '#394353',
      background: '#43677D',
      secondary: '#e4bc41',
      tertiary: '#58a4b7',
      dividers: '#6aa3b6',
      darkGrey: '#333',
      quoteText: '#666',
      gold: '#ffb82b',
      focus: '#43667d',
      placeholder: '#aaa',
      altGold: '#E4BC41',
      altBackground: '#43677D',
      altSecondary: '#434B64',
      error: '#FF4040',
      'text-color': '#43667d'
    },
    control: {
      border: {
        radius: 'none'
      }
    },
    focus: {
      shadow: {
        size: '0px'
      },
      outline: {
        size: '0px'
      }
    },
    input: {
      padding: {
        vertical: '5px'
      },
      weight: '400'
    },
    hover: {
      background: 'white'
    },
    placeholder: {
      color: '#999'
    },
    drop: {
      extend: 'background-color: #43677D; color: #fff;'
    },
    // maskedInput: {
    //   extend: 'background-color: #43677D'
    // }
  },
  maskedInput: {
    extend: 'color: white'
    // container: {
    //   extend: {
    //     background: '#f0f'
    //   }
    // }
  },
  icon: {
    extend: {
      color: 'white'
    }
  },
  calendar: {
    extend: 'background-color: white'
  },
  text: {
    color: 'primaryTwo',
    xsmall: {
      size: '12px'
    },
    small: {
      size: '14px'
    },
    medium: {
      size: '18px'
    },
    large: {
      size: '22px'
    },
    xlarge: {
      size: '26px'
    },
    xxlarge: {
      size: '34px'
    }
  },
  button: {
    default: {
      background: { color: 'brand' },
      color: 'white'
    },
    primary: {
      background: { color: 'brand' },
      color: 'white',
      font: {
        size: 'medium'
      },
      padding: {
        horizontal: 'large'
      }
    },
    secondary: {
      background: { color: 'secondary' },
      color: 'white',
      border: { color: 'secondary' },
      padding: {
        horizontal: 'medium'
      }
    },
    hover: {
      default: {
        color: '#fff',
        background: { color: 'secondary' }
      },
      primary: {
        color: '#fff',
        background: { color: 'secondary' }
      },
      secondary: {
        color: '#fff',
        background: { color: 'primary' }
      }
    },
    border: {
      radius: '12px'
    }
  },
  formField: {
    label: {
      margin: {
      },
      size: 'small'
    },
    content: {
      position: 'relative',
      margin: {
        bottom: 'none'
      },
      color: '#f00',
      width: '100%'
    },
    extend: {
      color: 'text-color'
    },
    info: {
      size: 'xsmall'
    },
    border: {
      error: {
        color: 'transparent'
      }
    },
    error: {
      background: {
      },
      container: {
        gap: 'xsmall',
        style: {
          // position: 'absolute',
          // bottom: '-30px',
          // right: '5px'
        }
      },
      size: 'small',
      weight: 'bold',
      color: 'secondary',
      margin: {
        bottom: 'xsmall',
        top: 'none',
        horizontal: 'none'
      }
    }
  },
  select: {
    container: {
      // extend needed otherwise an empty select dropdown
      // will have the dashboard background as it's background
      extend: `
        background-color: white;
      `
    },
    options: {
      container: {
        background: 'white'
      }
    }
  },
  dataTable: {
    header: {
      font: {
        weight: '500'
      }
    },
    groupHeader: {
      font: {
        weight: '500'
      }
    }
  },
  // tab
  tab: {
    active: {
      background: 'brand',
      color: 'white'
    },
    background: 'lightgrey',
    border: undefined,
    color: 'background',
    hover: {
      background: 'background',
      color: 'brand'
    },
    margin: undefined,
    pad: {
      bottom: undefined,
      horizontal: 'small',
      vertical: 'small'
    }
  },
  tabs: {
    gap: 'medium'
  },
  // textInput
  textInput: {
    extend: {
      background: 'tertiary',
      color: 'white'
    },
    focus: {
      border: {
        color: 'none'
      },
      outline: {
        size: 'none'
      }
    },
    padding: {
      vertical: '5px'
    },
    border: 'none'
  },
  checkBox: {
    color: 'secondary',
    extend: {
      color: 'white'
    },
    hover: {
      border: {
        color: 'secondary'
      }
    },
    border: {
      color: 'white'
    }
  },
  textArea: {
    extend: {
      background: 'tertiary',
      color: 'white'
    },
    border: 'none',
    focus: {

    },
    padding: {
      vertical: '5px'
    }
  },
  accordion: {
    border: {
      color: 'none'
    },
    icons: {
      color: 'white'
    }
  }
})
