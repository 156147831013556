/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { FormField as GFormField } from 'grommet'

/**
 * `FormField` renders a Grommet FormField
 */
class FormField extends React.Component {
  render () {
    var label = this?.props?.label
    // if (this?.props?.label) {
    // label = `${this.props.label} [${this.props.name}]`
    // }
    return (
      <GFormField
        {...this.props}
        label={label}
      />)
  }
}

FormField.propTypes = {
  /**
   * The component to insert in the FormField. Grommet will add update the form values when this field changes.
   * Any additional properties (such as initial value) you pass to FormField will be forwarded to this component.
   * The component may be custom as long it supports the properties of name, value, onChange (event => {}), while event has either event.value or event.target.value.
   *
   * "function"
   * "object"
   */
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object
  ]),
  /**
   * Whether the field should look disabled.
   *
   * true
   * false
   */
  disabled: PropTypes.bool,
  /**
   * Any error text describing issues with the field's value.
   *
   * "string"
   * "node"
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Any help text describing how the field works.
   *
   * "string"
   * "node"
   */
  help: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The id of the input element contained in this field.
   *
   * "string"
   */
  htmlFor: PropTypes.string,
  /**
   * Any informational text regarding the field's value.
   *
   * "string"
   * "node"
   */
  info: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * A short label describing the field.
   *
   * "string"
   * "node"
   */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The amount of margin around the component.
   * An object can be specified to distinguish horizontal margin, vertical margin, and margin on a particular side.
   *
   * "xsmall"
   * "small"
   * "medium"
   * "large"
   * "xlarge"
   * &nbsp;{
   * &nbsp;&nbsp;"vertical": "...",
   * &nbsp;&nbsp;"horizontal": "...",
   * &nbsp;&nbsp;"top": "...",
   * &nbsp;&nbsp;"bottom": "...",
   * &nbsp;&nbsp;"left": "...",
   * &nbsp;&nbsp;"right": "..."
   * &nbsp;}
   */
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The name of the value data when in a Form and the name of the input field.
   *
   * "string"
   */
  name: PropTypes.string,
  /**
   * Whether to add padding to align with the padding of TextInput.
   *
   * true
   * false
   */
  pad: PropTypes.bool,
  /**
   * Whether the field is required.
   */
  required: PropTypes.bool,
  /**
   * Validation rule when used within a grommet Form.
   * Provide an object with a regular expression, a function, or an array of these.
   * If a function is provided, it will be called with two arguments, the value for this field and the entire value object.
   * This permits validation to encompass multiple fields. The function should return a string message describing the validation issue,
   * if any, or an object with 'message' and 'status' properties.
   *
   * "function"
   * {
   * &nbsp;regexp: "new RegExp(...),"
   * &nbsp;message:
   * &nbsp;&nbsp;"string"
   * &nbsp;&nbsp;"node,"
   * &nbsp;status:
   * &nbsp;&nbsp;"error"
   * &nbsp;&nbsp;"info"
   * }
   * [
   * &nbsp;"function"
   * &nbsp;{
   * &nbsp;&nbsp;regexp: "new RegExp(...),"
   * &nbsp;&nbsp;message:
   * &nbsp;&nbsp;&nbsp;"string"
   * &nbsp;&nbsp;&nbsp;"node,"
   * &nbsp;&nbsp;status:
   * &nbsp;&nbsp;&nbsp;"error"
   * &nbsp;&nbsp;&nbsp;"info"
   * &nbsp;}
   * ]
   */
  validate: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.array
  ])
}

export default FormField
