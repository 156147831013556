/* eslint-disable */
import React, { useEffect, useState } from 'react'

// Components
import Box from '../components/simple/box/Box.js'
import Text from '../components/simple/text/Text.js'

function PrivacyLinks (props) {
  const [windowWidth, setWindowWidth] = useState(props.windowWidth)
  useEffect(() => { setWindowWidth(props.windowWidth); console.log('windowWidth', windowWidth) }, [props.windowWidth])

  var defaultStyles = {
    marginTop: 'large', background: 'transparent'
  }

    defaultStyles = {
      position: 'absolute',
      bottom: 0,
      left: 0,
      paddingTop: '10px',
      paddingLeft: '10px',
      paddingRight: '10px',
      paddingBottom: '5px',
      background: 'transparent', width:'100%', marginTop:'10px'
    }

  if (windowWidth.width < 1050) {
    defaultStyles.background = '#567288';
   }

  return (
    <Box style={defaultStyles} direction='row' gap='small'>
      <Text size='small'>
        <a style={{ color: '#e5bd41' }} rel='noreferrer' href='/cookies' target='_blank'>cookies</a>
      </Text>
      <Text size='small'>
        <a rel='noreferrer' href='https://www.meridianmortgages.net/privacy-policy' style={{ color: '#e5bd41' }} target='_blank'>privacy policy</a>
      </Text>
    </Box>
  )
}

export default PrivacyLinks
