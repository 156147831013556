/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Nope from 'nope-validator'
import moment from 'moment'

import Text from '../../simple/text/Text'
import H1 from '../../simple/heading/H1'

import Button from '../../simple/button/Button'

// Resources
import { content } from '../../../resources/content'
import FormField from '../../simple/formField/FormField'
import TextInput from '../../simple/input/TextInput'
import Box from '../../simple/box/Box'
import Form from '../../simple/form/Form'
import { MaskedInput } from 'grommet'
import OptionButtons from '../../compound/optionButtons/OptionButtons'

// const daysInMonth = (month) => new Date(2019, month, 0).getDate()

function PanelAboutYou (props) {
  const [windowSize, setWindowSize] = useState(props.window)
  useEffect(() => {
    setWindowSize(props.window)
  }, [props.window])

  const [formValues, setFormValues] = useState({})
  useEffect(() => {
    setFormValues(props.formValues)
  }, [props.formValues])

  const [numApplicants, setNumApplicants] = useState(1)

  useEffect(() => {
    if (formValues?.numApplicants && formValues.numApplicants !== numApplicants) {
      setNumApplicants(formValues.numApplicants)
    }
  }, [formValues])



  const [daysInMonth, setDaysInMonth] = useState(31)

  useEffect(() => {
    const monthToUse = formValues?.app1Dob ? formValues.app1Dob.split('/')[1] : 12
    const newDaysInMonth = new Date(2019, parseInt(monthToUse, 10), 0).getDate()
    setDaysInMonth(newDaysInMonth)
  }, [props.formValues.app1Dob])

  const [validationShape, setValidationShape] = useState({})

  useEffect(() => {
    var shape = {
      numApplicants: Nope.string().required(),
      app1Name: Nope.string().required().atLeast(2),
      app1Email: Nope.string().required().email(),
      app1Phone: Nope.string().required().regex(/^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/, 'Please enter a valid number'),
      app1Dob: Nope.string().required()

    }
    if (numApplicants === '2') {
      shape.app2Name = Nope.string().required().atLeast(2)
      shape.app2Email = Nope.string().required().email()
      shape.app2Phone = Nope.string().required().regex(/^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/, 'Please enter a valid number')
      shape.app2Dob = Nope.string().required()
    }

    setValidationShape(shape)
  }, [numApplicants])


  const Panel = Nope.object().shape(validationShape)

  const validateFormField = (key) => {
    const error = Panel.validate(formValues)

    if (['app1Dob', 'app2Dob'].includes(key)) {
      const dob = moment(formValues[key], 'DD/MM/YYYY')
      const yearThreshold = moment().subtract(18, 'years')
      if (dob.isSameOrAfter(yearThreshold)) {
        return {
          message: 'You must be 18 years or older',
          status: 'error'
        };
      }
    }


    // return an error
    if (error?.[key]) {
      return {
        message: error[key],
        status: 'error'
      }
    }
  }


  var optionButtonWidth = '200px'
  if (windowSize.width < 425) {
    optionButtonWidth = '45vw'
  }

  return (
    <Form
      value={formValues}
      validate='blur'
      onChange={(nextValue) => props.updateValues(nextValue)}
      onSubmit={() => props.nextStep()}
      style={{ marginBottom: '40px' }}
    >
      <H1 weight='bold' color='white'>{content.wizard.about_you.title}</H1>

      <Box gap='xsmall'>
        <Text color='white' size='small' margin={{ top: 'small', bottom: 'small' }}>{content.wizard.about_you.name_label}</Text>
      </Box>
      <Box direction='row-responsive' gap='small'>
        <FormField
          name='app1Name'
          width='100%'
          validate={() => validateFormField('app1Name')}
          direction='row'
          round='small'
          border={{ color: 'white', size: 'small' }}
          elevation='standard'
          background='altSecondary'
          style={{ position: 'relative' }}
        >
          <TextInput
            id='app1Name'
            name='app1Name'
            plain
            width='large'
            focusIndicator={false}
          />
        </FormField>
      </Box>


      <Box gap='xsmall'>
        <Text color='white' size='small' margin={{ top: 'small', bottom: 'small' }}>{content.wizard.about_you.email_label}</Text>
      </Box>
      <Box direction='row-responsive' gap='small'>
        <FormField
          name='app1Email'
          width='100%'
          validate={() => validateFormField('app1Email')}
          direction='row'
          round='small'
          border={{ color: 'white', size: 'small' }}
          elevation='standard'
          background='altSecondary'
          style={{ position: 'relative' }}
        >
          <TextInput
            id='app1Email'
            name='app1Email'
            type='email'
            plain
            width='large'
            focusIndicator={false}
          />
        </FormField>
      </Box>

      <Box gap='xsmall'>
        <Text color='white' size='small' margin={{ top: 'small', bottom: 'small' }}>{content.wizard.about_you.phone_label}</Text>
      </Box>
      <Box direction='row-responsive' gap='small'>
        <FormField
          name='app1Phone'
          width='100%'
          validate={() => validateFormField('app1Phone')}
          direction='row'
          round='small'
          border={{ color: 'white', size: 'small' }}
          elevation='standard'
          background='altSecondary'
          style={{ position: 'relative' }}
        >
          <TextInput
            id='app1Phone'
            name='app1Phone'
            plain
            type='tel'
            width='large'
            focusIndicator={false}
          />
        </FormField>
      </Box>

      <Box gap='xsmall'>
        <Text color='white' size='small' margin={{ top: 'small', bottom: 'small' }}>{content.wizard.about_you.dob_label}</Text>
      </Box>
      <Box direction='row-responsive' gap='small'>
        <FormField
          name='app1Dob'
          width='100%'
          validate={() => validateFormField('app1Dob')}
          direction='row'
          round='small'
          border={{ color: 'white', size: 'small' }}
          elevation='standard'
          background='altSecondary'
          style={{ position: 'relative' }}
        >

          <MaskedInput
            mask={[
              {
                length: [1, 2],
                regexp: /^[1-2][0-9]$|^3[0-1]$|^0?[1-9]$|^0$/,
                placeholder: 'dd'
              },
              { fixed: '/' },
              {
                length: [1, 2],
                regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                placeholder: 'mm'
              },
              { fixed: '/' },
              {
                length: 4,
                regexp:
                  /^[1-2]$|^19$|^20$|^19[0-9]$|^20[0-9]$|^19[0-9][0-9]$|^20[0-9][0-9]$/,
                placeholder: 'yyyy'
              },
            ]}
            id='app1Dob'
            name='app1Dob'
            type='tel'
            plain
            color='white'
            width='large'
            focusIndicator={false}
          />
        </FormField>
      </Box>

      <Box gap='xsmall'>
        <Text color='white' size='small' margin={{ top: 'small', bottom: 'small' }}>{content.wizard.earnings.with_someone_label}</Text>
      </Box>
      <Box direction='row-responsive' gap='small'>
        <FormField
          validate={() => validateFormField('numApplicants')}
          htmlFor='numApplicants'
          name='numApplicants'
        >
          <OptionButtons
            id='numApplicants'
            name='numApplicants'
            buttonBackground='altSecondary'
            buttonActive='secondary'
            buttonHeight='130px'
            buttonWidth={optionButtonWidth}
            fontSize='medium'
            fontWeight='normal'
            options={[
              {
                label: content.wizard.earnings.with_someone_yes,
                value: '2',
                iconUrl: '/icon-people.svg'
              },
              {
                label: content.wizard.earnings.with_someone_no,
                value: '1',
                iconUrl: '/icon-person.svg'
              }
            ]}
          />
        </FormField>
      </Box>

      {numApplicants === '2' &&
        <>
          <H1 weight='bold' color='white'>{content.wizard.about_you.title_other}</H1>

          <Box gap='xsmall'>
            <Text color='white' size='small' margin={{ top: 'small', bottom: 'small' }}>{content.wizard.about_you.name_label}</Text>
          </Box>
          <Box direction='row-responsive' gap='small'>
            <FormField
              name='app2Name'
              width='100%'
              validate={() => validateFormField('app2Name')}
              direction='row'
              round='small'
              border={{ color: 'white', size: 'small' }}
              elevation='standard'
              background='altSecondary'
              style={{ position: 'relative' }}
            >
              <TextInput
                id='app2Name'
                name='app2Name'
                plain
                width='large'
                focusIndicator={false}
              />
            </FormField>
          </Box>


          <Box gap='xsmall'>
            <Text color='white' size='small' margin={{ top: 'small', bottom: 'small' }}>{content.wizard.about_you.email_label}</Text>
          </Box>
          <Box direction='row-responsive' gap='small'>
            <FormField
              name='app2Email'
              width='100%'
              validate={() => validateFormField('app2Email')}
              direction='row'
              round='small'
              border={{ color: 'white', size: 'small' }}
              elevation='standard'
              background='altSecondary'
              style={{ position: 'relative' }}
            >
              <TextInput
                id='app2Email'
                name='app2Email'
                type='email'
                plain
                width='large'
                focusIndicator={false}
              />
            </FormField>
          </Box>

          <Box gap='xsmall'>
            <Text color='white' size='small' margin={{ top: 'small', bottom: 'small' }}>{content.wizard.about_you.phone_label}</Text>
          </Box>
          <Box direction='row-responsive' gap='small'>
            <FormField
              name='app2Phone'
              width='100%'
              validate={() => validateFormField('app2Phone')}
              direction='row'
              round='small'
              border={{ color: 'white', size: 'small' }}
              elevation='standard'
              background='altSecondary'
              style={{ position: 'relative' }}
            >
              <TextInput
                id='app2Phone'
                name='app2Phone'
                type='tel'
                plain
                width='large'
                focusIndicator={false}
              />
            </FormField>
          </Box>

          <Box gap='xsmall'>
            <Text color='white' size='small' margin={{ top: 'small', bottom: 'small' }}>{content.wizard.about_you.dob_label}</Text>
          </Box>
          <Box direction='row-responsive' gap='small'>
            <FormField
              name='app2Dob'
              width='100%'
              validate={() => validateFormField('app2Dob')}
              direction='row'
              round='small'
              border={{ color: 'white', size: 'small' }}
              elevation='standard'
              background='altSecondary'
              style={{ position: 'relative' }}
            >

              <MaskedInput
                mask={[
                  {
                    length: [1, 2],
                    regexp: /^[1-2][0-9]$|^3[0-1]$|^0?[1-9]$|^0$/,
                    placeholder: 'dd'
                  },
                  { fixed: '/' },
                  {
                    length: [1, 2],
                    regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                    placeholder: 'mm'
                  },
                  { fixed: '/' },
                  {
                    length: 4,
                    regexp:
                      /^[1-2]$|^19$|^20$|^19[0-9]$|^20[0-9]$|^19[0-9][0-9]$|^20[0-9][0-9]$/,
                    placeholder: 'yyyy'
                  },
                ]}
                id='app2Dob'
                name='app2Dob'
                type='tel'
                plain
                color='white'
                width='large'
                focusIndicator={false}
              />
            </FormField>
          </Box>
        </>}

      <Box direction='row-responsive' gap='small' width='medium' margin={{ top: 'medium' }}>
        <Button
          label={content.wizard.controls.button_next}
          secondary
          type='submit'
          width='medium'
          fill
        />
      </Box>

    </Form>)
}

export default PanelAboutYou
