/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { Text as GText } from 'grommet'

/**
 * `Text` renders a Text label as a Grommet Text component
 */
class Text extends React.Component {
  render () {
    return (
      <GText
        {...this.props}
        className={this.props.className}
      >
        {this.props.children}
      </GText>)
  }
}

Text.propTypes = {
  /**
   * Custom label to be used by screen readers. When provided, an aria-label will be added to the element.  
   *   
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * How to align along the cross axis when contained in a Box or along the column axis when contained in a Grid.  
   *   
   *  "start"  
   *  "end"  
   *  "center"  
   *  "stretch"
   */
  alignSelf: PropTypes.string,
  /**
   * The DOM tag or react component to use for the element.  
   *   
   * "string"  
   * function  
   * "element"
   */
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  /**
   * A color identifier to use for the text color.  
   *   
   * "status-critical"
   */
  color: PropTypes.string,
  /**
   * The name of the area to place this inside a parent Grid.  
   *   
   * "a parent grid area name"
   */
  gridArea: PropTypes.string,
  /**
   * The amount of margin around the component.  
   * An object can be specified to distinguish horizontal margin, vertical margin, and margin on a particular side.  
   *   
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * &nbsp;{  
   * &nbsp;&nbsp;"vertical": "...",  
   * &nbsp;&nbsp;"horizontal": "...",  
   * &nbsp;&nbsp;"top": "...",  
   * &nbsp;&nbsp;"bottom": "...",  
   * &nbsp;&nbsp;"left": "...",  
   * &nbsp;&nbsp;"right": "..."  
   * &nbsp;}
   */
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The font size and line height are primarily driven by the chosen tag.  
   * But, it can be adjusted via this size property.  
   * The tag should be set for semantic correctness and accessibility. This size property allows for stylistic adjustments.  
   *   
   * "xsmall"  
   * "small"  
   * **"medium"**  
   * "large"  
   * "xlarge"  
   * "xxlarge"
   */  
  size: PropTypes.string,
  /**
   * The DOM tag to use for the element. NOTE: This is deprecated in favor of indicating the DOM tag via the 'as' property.  
   *   
   * "tag"  
   * "function"
   */
  tag: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  /**
   * How to align the text inside the component.  
   *     
   * **"start"**  
   * "center"  
   * "end"
   */
  textAlign: PropTypes.string,
  /**
   * Restrict the text to a single line and truncate with ellipsis if it is too long to all fit.  
   *   
   * true  
   * **false**
   */
  truncate: PropTypes.bool,
  /**
   * Font weight  
   *   
   * "normal"  
   * "bold"  
   * number
   */  
  weight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  /**
   * Whether words should break when reaching the end of a line.  
   *   
   * **"normal"**  
   * "break-all"  
   * "keep-all"  
   * "break-word"
   */
  wordBreak: PropTypes.string
}

export default Text
