/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Box from '../simple/box/Box'
import WizardProgress from './WizardProgress'
function WizardWrapper (props) {
  const [panels, setPanels] = useState(props.panels)
  useEffect(() => {
    setPanels(props.panels)
  }, [props.panels])

  const [step, setStep] = useState(props.step)
  useEffect(() => {
    setStep(props.step)
  }, [props.step])

  const renderCurrentStep = () => {
    return panels[step]
  }
  return (
    <Box margin={{ bottom: 'xxlarge' }}>
      {step > 0 && <Box margin={{ top: 'large', bottom: 'small' }}><WizardProgress step={step - 1} numSteps={panels.length} /></Box>}
      {renderCurrentStep()}
    </Box>)
}

export default WizardWrapper
