/* eslint-disable */
import moment from "moment";
import Nope from "nope-validator";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { settings } from "../../config";
import { content } from "../../resources/content";
import FormService from "../../services/FormService";
import NotificationService from "../../services/NotificationService";
import Call from "../compound/signup.js/Call";
import Consent from "../compound/signup.js/Consent";
import Box from "../simple/box/Box";
import Button from "../simple/button/Button";
import Form from "../simple/form/Form";
import Text from "../simple/text/Text";

function WizardSignup(props) {
  const history = useHistory();
  const [formValues, setFormValues] = useState({});
  const [submissionId, setSubmissionId] = useState(props.submissionId);
  const [submissionUUID, setSubmissionUUID] = useState(props.submissionUUID);
  
  useEffect(() => {
    setSubmissionId(props.submissionId);
    setSubmissionUUID(props.submissionUUID);
  }, [props.submissionId, props.submissionUUID]);

  const FormSchema = Nope.object().shape({
    dateToCall: Nope.string().required(),
    timeToCall: Nope.string().required(),
    notes: Nope.string(),
    acceptTC: Nope.boolean(true).required(),
  });

  const validateFormField = (key) => {
    const error = FormSchema.validate(formValues);
    // return an error
    if (error?.[key]) {
      return {
        message: error[key],
        status: "error",
      };
    }
  };

  const onSubmit = async () => {

    // For some reason, the T&Cs validation is sporadic
    // This is an extra check to ensure the form can't be submitted
    if (!formValues.acceptTC) {
      window.alert('In order to continue, you must consent for your data to be processed by Meridian')
      return false
    }

    try {
      var responses = [];
      for (const key in formValues) {
        const value = formValues[key];
        responses.push({
          questionKey: key,
          questionText: key,
          response: value,
        })
      }

      await FormService.updateSubmission(settings.apiToken, submissionId, {
        user: settings.userId,
        status: "Published",
        type: "meridian-signupform",
        reference: "",
        responses,
      }, submissionUUID);

      history.push("/success");
    } catch (err) {
      console.error(err);
      NotificationService.error("There was an error submitting this request");
      return;
    }
  };

  var inputBottomMargin = "medium";
  var inputTwoColumnWidth = "50%";
  if (props?.window?.width < 840) {
    inputBottomMargin = "large";
    inputTwoColumnWidth = "100%";
  }

  return (
    <Form
      value={formValues}
      validate="blur"
      onChange={(nextValue) => setFormValues(nextValue)}
      onSubmit={() => onSubmit()}
      margin={{ bottom: 'xlarge' }}
    >
      <Box margin={{ bottom: "medium" }}>
        <Text
          color="white"
          size="48px"
          weight="bold"
          style={{ marginBottom: "50px" }}
        >
          Next Steps
        </Text>
        <Call
          formValues={formValues}
          validateFormField={validateFormField}
          inputTwoColumnWidth={inputTwoColumnWidth}
          inputBottomMargin={inputBottomMargin}
        />
      </Box>
      {formValues.whenToCall && (formValues.dateToCall || formValues.whenToCall === content.wizard.results.call_me_asap) && (
        <Box
          direction="row-responsive"
          margin={{ top: "medium", bottom: "medium" }}
        >
          <Button
            label={content.calculatorResult.submit_button_label}
            secondary
            type="submit"
            fill
          />
        </Box>
      )}
      <Text
        color="white"
        size="xsmall"
        margin={{ top: "medium", bottom: "small" }}
      >
        {content.calculatorResult.caveat_text1}
        <br /><br />
        {content.calculatorResult.caveat_text2}
      </Text>
    </Form>
  );
}

export default WizardSignup;
