/* eslint-disable */
import React from "react";

// Components
import Box from "../../simple/box/Box";
import Text from "../../simple/text/Text";
import FormField from "../../simple/formField/FormField";
import CheckBox from "../../simple/input/CheckBox";

// Resources
import { content } from "../../../resources/content";

function Consent(props) {
  return (
    <FormField
      htmlFor="acceptTC"
      name="acceptTC"
      style={{ position: "relative", marginBottom: "20px" }}
      validate={() => props.validateFormField("acceptTC")}
    >
      <CheckBox
        label={
          <Box>
            {props.formValues?.builder &&
            props.formValues?.type === "Sales Negotiator" ? (
              <Text size="small" weight="bold">
                {content.signup.field_labels.consent_label_sales_negotiator.replace(
                  "{builder}",
                  props.formValues.builder
                )}
              </Text>
            ) : (
              <Text size="small" weight="bold">
                {content.signup.field_labels.consent_label_client}
              </Text>
            )}
          </Box>
        }
        name="acceptTC"
        round="small"
      />
    </FormField>
  );
}

export default Consent;
