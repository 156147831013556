/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Accordion, AccordionPanel, Anchor, Button } from 'grommet'
import RingLoader from 'react-spinners/RingLoader'
import Dinero from 'dinero.js'

import Box from "../simple/box/Box.js";
import Text from "../simple/text/Text";
import Divider from "../simple/divider/Divider";
import TextInput from "../simple/input/TextInput";
import FormField from "../simple/formField/FormField";
import LoanCalc from "loan-calc";
import H2 from "../simple/heading/H2";

// Resources
import { content } from "../../resources/content";
import { settings } from "../../config.js";
import { regions } from "../../utils/builders.js";

// Services
import NotificationService from "../../services/NotificationService";
import FormService from "../../services/FormService";
import CalculateService from "../../services/CalculateService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WizardSignup from "../wizard/WizardSignup.js";

function CalculatorResults(props) {
  const [formValues, setFormValues] = useState();

  const [rawHTBData, setRawHTBData] = useState({});
  const [rawSOData, setRawSOData] = useState({});
  const [rawMabsData, setRawMabsData] = useState({});

  const [rawAffordabilityData, setRawAffordabilityData] = useState({});
  const [mortgageAmount, setMortgageAmount] = useState();
  const [monthlyRentPCM, setMonthlyRentPCM] = useState();
  const [mortgageSharedOwnership, setMortgageSharedOwnership] = useState();
  const [sharedOwnershipPCM, setSharedOwnershipPCM] = useState();
  const [mortgageHelpToBuy, setMortgageHelpToBuy] = useState();
  const [helpToBuyPCM, setHelpToBuyPCM] = useState();

  const [landgResultsDisplay, setLandgResultsDisplay] = useState();

  const [hasAffordability, setHasAffordability] = useState(true)
  const [isAffordable, setIsAffordable] = useState(false)
  const [numAffordableLenders, setNumAffordableLenders] = useState(0);

  const [minMonthlyAmount, setMinMonthlyAmount] = useState(0);
  const [maxMonthlyAmount, setMaxMonthlyAmount] = useState(0);

  const [showLoading, setShowLoading] = useState(false);

  const [editResults, setEditResults] = useState(false);

  const [newPurchasePrice, setNewPurchasePrice] = useState(0);
  const [newDeposit, setNewDeposit] = useState(0);
  const [newTerm, setNewTerm] = useState(0);

  const saveNewValues = async () => {
    setEditResults(false);
    try {
      const cleanPurchasePrice = parseInt(
        newPurchasePrice.match(/\d+/gi).join("")
      );
      const cleanDeposit = parseInt(newDeposit.match(/\d+/gi).join(""));

      var responses = [
        {
          questionKey: "term",
          questionText: "term",
          response: parseInt(newTerm),
        },
        {
          questionKey: "propertyPrice",
          questionText: "propertyPrice",
          response: cleanPurchasePrice,
        },
        {
          questionKey: "deposit",
          questionText: "deposit",
          response: cleanDeposit,
        },
      ];
      // recalculate the loan amount
      var newLoan = cleanPurchasePrice - cleanDeposit;
      responses.push({
        questionKey: "loanAmount",
        questionText: "loanAmount",
        response: newLoan,
      });
      setShowLoading(true);
      await FormService.updateSubmission(
        settings.apiToken,
        props.submissionId,
        {
          user: settings.userId,
          status: "Draft",
          type: "meridian-signupform",
          reference: "",
          responses,
        },
        props.uuid
      );

      // get the data again
      retrieveFormData();
    } catch (err) {
      console.error(err);
      setShowLoading(false);
      NotificationService.error("There was an error updating this submission");
    }
  };

  useEffect(() => {
    retrieveFormData();
  }, [props.submissionId]);

  useEffect(() => {
    if (formValues?.propertyPrice) {
      setNewPurchasePrice(
        Dinero({
          amount: parseInt(formValues.propertyPrice),
          currency: "GBP",
          precision: 0,
        }).toFormat("0,0")
      );
    }
    if (formValues?.deposit) {
      setNewDeposit(
        Dinero({
          amount: parseInt(formValues.deposit),
          currency: "GBP",
          precision: 0,
        }).toFormat("0,0")
      );
    }
    if (formValues?.term) {
      setNewTerm(
        Dinero({
          amount: parseInt(formValues.term),
          currency: "GBP",
          precision: 0,
        }).toFormat("0,0")
      );
    }

    calculateEligibility();
  }, [formValues]);

  const formatCurrency = (amount) => {
    if (!amount) {
      return 0;
    }
    amount = parseInt(amount);
    return Dinero({ amount, currency: "GBP", precision: 0 }).toFormat("0,0");
  };

  const retrieveFormData = async () => {
    if (!props.submissionId) {
      window.alert("Missing submission ID");
      return false;
    }

    const params = {
      fields: "submission,questionKey,questionText,response",
      limit: 500,
    };
    const where = {
      submission: props.submissionId,
    };

    const response = await FormService.getResponses(
      settings.apiToken,
      params,
      where,
      props.uuid
    );
    if (response?.error) {
      NotificationService.error(
        "There was an error retrieving this submission"
      );
    } else if (response?.data) {
      var thisFormValues = {};
      response.data.forEach((p) => {
        if (p?.questionKey) {
          thisFormValues[p.questionKey] = p.response;
        }
      });

      setFormValues(thisFormValues);
    }
  };

  const calculateEligibility = async () => {
    setShowLoading(true);

    if (!props.submissionId) {
      return false;
    }
    if (!formValues?.loanAmount) {
      return false;
    }

    try {
      var landg = await FormService.getLandg(
        settings.apiToken,
        props.submissionId
      );
    } catch (err) {
      console.error(err);
    }

    if (landg?.error) {
      console.log(landg?.error);
      NotificationService.error(
        "There was an error retrieving this submission"
      );
      setShowLoading(false);
      return;
    }

    await getMabsData(props.submissionId).catch(console.error);

    if (!landg?.data?.result?.quotes) {
      NotificationService.warning(
        "There was an error retrieving this submission"
      );
      setShowLoading(false);
      return;
    }

    const params = {
    };
    try {
      var ownership = await CalculateService.sharedOwnership(
        settings.apiToken,
        params,
        { id: props.submissionId }
      );
    } catch (err) {
      console.error(err);
    }

    if (ownership?.summary) {
      setRawSOData(ownership.summary);
    }

    try {
      var helpToBuy = await CalculateService.helpToBuy(
        settings.apiToken,
        params,
        { id: props.submissionId }
      );
    } catch (err) {
      console.error(err);
    }
    if (helpToBuy?.summary) {
      setRawHTBData(helpToBuy.summary);
      setMortgageHelpToBuy(helpToBuy.summary.mortgageRequirement.toFixed(2));
      setHelpToBuyPCM(helpToBuy.summary.MortgagePCM.toFixed(2));
    }

    if (landg?.data) {
      setRawAffordabilityData(landg.data);

      var maxLoan = 0;
      var affordableLendersCount = 0;
      if (landg.data?.result?.quotes?.length) {
        maxLoan = landg.data.result.quotes[0].maximumValue;
        affordableLendersCount = landg.data.result.quotes.filter(x => x.maximumValue >= parseInt(formValues?.loanAmount)).length
      }

      setNumAffordableLenders(affordableLendersCount);
      setMortgageAmount(maxLoan);
      setShowLoading(false);
      setHasAffordability(true);

      // is this affordable?
      setIsAffordable(parseInt(maxLoan) > parseInt(formValues?.loanAmount));
    }
  };

  useEffect(() => {
    if (rawAffordabilityData?.result?.quotes) {
      var pieces = [];
      var i = 1;
      for (const quote of rawAffordabilityData.result.quotes) {
        if (quote?.maximumValue > 0) {
          pieces.push(
            <Text weight="bold" color="white" margin="xsmall" size="xsmall">
              Quote {i}
            </Text>
          );
          for (const key in quote) {
            const value = quote[key];
            if (value !== null) {
              pieces.push(
                <Text color="white" margin="xsmall" size="xsmall">
                  <Text
                    weight="bold"
                    color="white"
                    margin="xsmall"
                    size="xsmall"
                  >
                    {key}
                  </Text>
                  {value}
                </Text>
              );
            }
          }
        }
        i++;
      }

      setLandgResultsDisplay(pieces);
    }
  }, [rawAffordabilityData]);

  const getMabsData = async (submissionId) => {
    // update the submission with the new affordability
    try {
      const data = {
        user: settings.userId,
        status: "Draft",
        type: "meridian-signupform",
        reference: "",
        responses: [
          {
            questionKey: "originalLoanRequested",
            questionText: "Requested Loan Amount",
            response: formValues?.loanAmount,
          },
          {
            questionKey: "maxLoanFromLenders",
            questionText: "Max Loan From Lenders",
            response: mortgageAmount,
          },
        ],
      };
      var response = await FormService.updateSubmission(
        settings.apiToken,
        submissionId,
        data,
        props.uuid
      );
    } catch (err) {
      console.error(err);
    }

    try {
      var mabs = await FormService.getMabs(
        settings.apiToken,
        props.submissionId
      );
    } catch (err) {
      console.error(err);
    }

    if (mabs?.error) {
      console.error(mabs?.error);
      NotificationService.error(
        "There was an error retrieving the data for this submission"
      );
    }

    var mabData = []
    var minAmount = 0;
    var maxAmount = 0;

    if (mabs.data.length) {
      // the live api just returns an array of data
      setRawMabsData(mabs.data);
      mabData = mabs.data
    }

    // Use precaculated values if they exist
    if (mabs?.lowest && mabs?.highest) {
      minAmount = parseInt(mabs.lowest) 
      maxAmount = parseInt(mabs.highest) 
    } else {
      const productToShow = mabData.slice(0, 100);
      for (const product of productToShow) {
        try {
          var monthlyCost = LoanCalc.paymentCalc({
            amount: parseInt(formValues?.loanAmount),
            rate: parseInt(product.initialPayRate),
            termMonths: formValues?.term * 12,
          });

          // simplify
          monthlyCost = parseInt(monthlyCost);

          if (minAmount === 0 || monthlyCost < minAmount) {
            minAmount = monthlyCost;
          }

          if (maxAmount === 0 || monthlyCost > maxAmount) {
            maxAmount = monthlyCost;
          }
        } catch (err) {
          console.error(err);
        }
      }
    }


    setMinMonthlyAmount(minAmount);
    setMaxMonthlyAmount(maxAmount);
  };

  const renderMabsProducts = (count = 3) => {
    var rows = [];

    // get the first x number of products
    const productToShow = rawMabsData.slice(0, count);

    var i = 1;
    for (const product of productToShow) {
      rows.push(
        <Box gap="xxsmall" margin={{ bottom: "small" }}>
          <Box width="100%">
            <Text size="small" color="white" weight="bold">
              #{i}) {product.productName}
            </Text>
          </Box>
          <Box width="100%">
            <Text size="small" color="white">
              Reference: {product.lenderProductReference}, Max LTV:{" "}
              {product.maxLTVAvailable}%
            </Text>
          </Box>
          <Box width="100%">
            <Text size="small" color="white">
              Max Loan: &pound;{product.maxLoanAvailable}, Arrangement Fee:
              &pound;{product.arrangementFee}
            </Text>
          </Box>
          <Box width="100%">
            <Text size="small" color="white">
              initialRatePeriod: {product.initialRatePeriod}, productName:{" "}
              {product.productName}
            </Text>
          </Box>
          <Box width="100%">
            <Text size="small" color="white">
              initialPayRate: {product.initialPayRate}, arrangementFee:{" "}
              {product.arrangementFee}
            </Text>
          </Box>
          <Box width="100%">
            <Text size="small" color="white">
              productType: {product.productType}
            </Text>
          </Box>
          <Box width="100%">
            <Text size="small" color="white">
              initialMonthlyPayment: {product.initialMonthlyPayment}
            </Text>
          </Box>
        </Box>
      );
      i++;
    }

    return <Box>{rows}</Box>;
  };

  const renderFormValues = () => {
    var toRender = []
    for (const key in formValues) {
      var value = formValues[key] || ''
      toRender.push(<Text color='white' size='small'><Text weight='bold' color='white' size='small'>{key}</Text>: {value}</Text>)
    }
    return toRender
  }

  const renderEditForm = () => {
    return (
      <>
        {!editResults && (
          <Text color="white" margin="small">
            {content.calculatorResult.edit_label}
            <Text color="white" margin="small">
              <Text weight="bold">{content.calculatorResult.edit_link}</Text>
              <Anchor
                margin={{ left: "small" }}
                onClick={() => setEditResults(true)}
              >
                <FontAwesomeIcon color="#fff" icon={["fa", "pencil"]} />
              </Anchor>
            </Text>
          </Text>
        )}
        {editResults && (
          <Box margin={{ top: "medium", left: "small" }}>
            <H2 weight="bold" color="white" margin={{ bottom: "small" }}>
              {content.wizard.new_home.title}
            </H2>
            <Box>
              <Box gap="xsmall">
                <Text
                  color="white"
                  size="small"
                  margin={{ top: "small", bottom: "small" }}
                >
                  {content.wizard.new_home.property_price_label}
                </Text>
              </Box>
              <FormField
                name="purchasePrice"
                width="100%"
                direction="row"
                round="small"
                border={{ color: "white", size: "small" }}
                elevation="standard"
                background={settings.darkMode ? "altSecondary" : "background"}
                style={{ position: "relative" }}
              >
                <TextInput
                  id="purchasePrice"
                  name="purchasePrice"
                  icon={
                    <FontAwesomeIcon
                      icon="fa-solid fa-sterling-sign"
                      color="white"
                    />
                  }
                  plain
                  width="large"
                  focusIndicator={false}
                  value={newPurchasePrice}
                  onChange={(event) => {
                    const amount = event.target.value
                      ? parseInt(event.target.value.match(/\d+/gi).join(""))
                      : 0;
                    const formattedAmount = Dinero({
                      amount,
                      currency: "GBP",
                      precision: 0,
                    }).toFormat("0,0");
                    setNewPurchasePrice(formattedAmount);
                  }}
                />
              </FormField>
            </Box>

            <Box>
              <Box gap="xsmall">
                <Text
                  color="white"
                  size="small"
                  margin={{ top: "small", bottom: "small" }}
                >
                  {content.wizard.new_home.deposit_label}
                </Text>
              </Box>
              <FormField
                name="deposit"
                width="100%"
                direction="row"
                round="small"
                border={{ color: "white", size: "small" }}
                elevation="standard"
                background={settings.darkMode ? "altSecondary" : "background"}
                style={{ position: "relative" }}
              >
                <TextInput
                  id="deposit"
                  name="deposit"
                  icon={
                    <FontAwesomeIcon
                      icon="fa-solid fa-sterling-sign"
                      color="white"
                    />
                  }
                  plain
                  width="large"
                  focusIndicator={false}
                  value={newDeposit}
                  onChange={(event) => {
                    const amount = event.target.value
                      ? parseInt(event.target.value.match(/\d+/gi).join(""))
                      : 0;
                    const formattedAmount = Dinero({
                      amount,
                      currency: "GBP",
                      precision: 0,
                    }).toFormat("0,0");
                    setNewDeposit(formattedAmount);
                  }}
                />
              </FormField>
            </Box>
            <Box>
              <Box gap="xsmall">
                <Text
                  color="white"
                  size="small"
                  margin={{ top: "small", bottom: "small" }}
                >
                  {content.wizard.new_home.term_label}
                </Text>
              </Box>
              <FormField
                name="term"
                width="100%"
                direction="row"
                round="small"
                border={{ color: "white", size: "small" }}
                elevation="standard"
                background={settings.darkMode ? "altSecondary" : "background"}
                style={{ position: "relative" }}
              >
                <TextInput
                  id="term"
                  name="term"
                  plain
                  width="large"
                  focusIndicator={false}
                  value={newTerm}
                  onChange={(event) => setNewTerm(event.target.value)}
                />
              </FormField>
            </Box>
            <Box
              direction="row-responsive"
              margin={{ top: "medium", bottom: "medium" }}
              width="small"
            >
              <Button
                label={content.calculatorResult.submit_button_label}
                secondary
                onClick={() => {
                  saveNewValues();
                }}
                fill
              />
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      {showLoading && (
        <>
          <Text
            margin={{ bottom: "medium" }}
            color="white"
            size="38px"
            weight="bold"
          >
            {content.wizard.results.loading}
          </Text>
          <RingLoader color="#debc58" />
        </>
      )}
      {!showLoading && hasAffordability && isAffordable && (
        <Box round="small" style={{ borderBottom: "none" }}>
          <Text color="white" size="48px" weight="bold">
            You’ve Passed!
          </Text>

          <Box
            background="primaryDark"
            round="small"
            pad="none"
            margin={{ top: "medium", bottom: "small" }}
          >
            <Text color="white" margin="small">
              <Text color="white" size="xlarge" weight="bold">
                {numAffordableLenders} lenders
              </Text>
              <Text color="white"> are likely to lend more than your required </Text>
              <Text color="white" size="xlarge" weight="bold">
                £{formatCurrency(formValues?.loanAmount)}
              </Text>
            </Text>
            <Text
              color="white"
              width="100%"
              margin={{ top: "small", horizontal: "small" }}
            >
              To borrow this amount, you are looking at paying between{" "}
            </Text>
            <Text
              color="white"
              size="large"
              weight="bold"
              margin={{ bottom: "small", horizontal: "small" }}
            >
              £{formatCurrency(minMonthlyAmount || 0)} and £
              {formatCurrency(maxMonthlyAmount || 0)} per month over a{" "}
              {formValues?.term} year term.
            </Text>
          </Box>
          <Box
            background="secondary"
            round="small"
            pad="none"
            margin={{ bottom: "medium" }}
          >
            <Text color="primaryDark" margin="small" size="large" weight="bold">
              {" "}
              You could potentially borrow up to a maximum amount of £
              {formatCurrency(mortgageAmount)}.
            </Text>
          </Box>
          <Box
            background="secondary"
            round="small"
            pad="none"
            margin={{ bottom: "medium" }}
          >
            <Text color="primaryDark" margin="small" size="medium" weight='normal'>These results are based on the limited information provided and are only an estimate</Text>

          </Box>
          <Text color="white" margin={{ top: "small", bottom: 'large' }}>
            Let’s now arrange a time to speak to one of our Mortgage Experts to
            continue the journey in getting you into your new home!
          </Text>

        </Box>
      )}
      {!showLoading && hasAffordability && !isAffordable && (
        <Box
          round="small"
          style={{ borderBottom: "none" }}
          margin={{ bottom: "medium" }}
        >
          <Box
            background="primaryDark"
            round="small"
            pad="none"
            margin={{ top: "medium" }}
          >
            <Text color="white" margin="small" size="xlarge" weight="bold">
              It looks like we’re just short of your required borrowing amount
              of £{formatCurrency(formValues?.loanAmount)}.
            </Text>
          </Box>
          <Box
            background="primaryDark"
            round="small"
            pad="none"
            margin={{ top: "medium" }}
          >
            <Text color="white" margin="small" size="medium" weight='normal'>These results are based on the limited information provided and are only an estimate.</Text>
          </Box>
          <Text
            color="white"
            margin={{ top: "medium", bottom: "medium" }}
            size="large"
            weight="bold"
          >
            Don't Worry!
          </Text>

          <Text color="white" margin={{ top: "small" }}>
            Let’s now arrange a time to speak to one of our Mortgage Experts to
            continue the journey in getting you into your new home!
          </Text>

        </Box>)}
      {(settings.debug || props.debug) && (<>
        <Box round='small'>
          <Accordion>
            <AccordionPanel background={settings.darkMode ? 'altSecondary' : 'background'} round='small' label={<Text color='white' weight='bold' margin='small'>[DEBUG] What we've sent</Text>}>
              <Box pad='medium' background={settings.darkMode ? 'altSecondary' : 'background'} round='small' style={{ borderBottom: 'none' }}>
                {renderFormValues()}
              </Box>
            </AccordionPanel>
          </Accordion>
        </Box>

        <Box round='small'>
          <Accordion>
            <AccordionPanel background={settings.darkMode ? 'altSecondary' : 'background' } round='small' label={<Text color='white' weight='bold' margin='small'>[DEBUG] Results</Text>}>
              <Box background={settings.darkMode ? 'altSecondary' : 'background' } round='small' style={{ borderBottom: 'none' }}>
                <Text size='xlarge' color='white' weight='bold' margin='small'>RESULT: </Text>
                <Text color='white' margin='xsmall'>{content.calculatorResult.accordion.straight_label}</Text>
                <Text color='white' margin='xsmall' size='xsmall'>Mortgage Amount: £{mortgageAmount} </Text>
                <Text color='white' margin='xsmall' size='xsmall'>Loan Amount: £{formValues?.loanAmount} </Text>
                <Text color='white' margin='xsmall' size='xsmall'>Monthly Rent PCM: £{monthlyRentPCM} </Text>
              </Box>
            </AccordionPanel>
          </Accordion>

          <Accordion>
            <AccordionPanel background={settings.darkMode ? 'altSecondary' : 'background' } round='small' label={<Text color='white' weight='bold' margin='small'>[DEBUG] MAB Results</Text>}>
              <Box background={settings.darkMode ? 'altSecondary' : 'background' } round='small' style={{ borderBottom: 'none' }}>
                <Box margin='small'>
                  <Text weight='bold' color='white'>27Tech/Mab</Text>
                  {rawMabsData.length > 0 && renderMabsProducts(20)}
                  {!rawMabsData.length && <Text color='white' size='small'>Nothing to display...</Text>}
                </Box>
              </Box>
            </AccordionPanel>
          </Accordion>

          <Accordion>
            <AccordionPanel
              background={settings.darkMode ? "altSecondary" : "background"}
              round="small"
              label={
                <Text color="white" weight="bold" margin="small">
                  [DEBUG] MAB Results
                </Text>
              }
            >
              <Box
                background={settings.darkMode ? "altSecondary" : "background"}
                round="small"
                style={{ borderBottom: "none" }}
              >
                <Box margin="small">
                  <Text weight="bold" color="white">
                    27Tech/Mab
                  </Text>
                  {rawMabsData.length > 0 && renderMabsProducts(20)}
                  {!rawMabsData.length && (
                    <Text color="white" size="small">
                      Nothing to display...
                    </Text>
                  )}
                </Box>
              </Box>
            </AccordionPanel>
          </Accordion>

          <Accordion>
            <AccordionPanel
              background={settings.darkMode ? "altSecondary" : "background"}
              round="small"
              label={
                <Text color="white" weight="bold" margin="small">
                  [DEBUG] L&amp;G Results
                </Text>
              }
            >
              <Box
                background={settings.darkMode ? "altSecondary" : "background"}
                round="small"
                style={{ borderBottom: "none" }}
              >
                <Box margin="small">
                  {rawAffordabilityData?.result && (
                    <Box>
                      <Text weight="bold" color="white">
                        L&amp;G
                      </Text>

                      <Text color="white" margin="xsmall" size="xsmall">
                        pollJobID: {rawAffordabilityData?.result?.pollJobID}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        smartrFitSearchId:{" "}
                        {rawAffordabilityData?.result?.smartrFitSearchId}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        # quotes:{" "}
                        {rawAffordabilityData?.result?.quotes?.length}{" "}
                      </Text>
                      {landgResultsDisplay}
                      <Text color="white" margin="xsmall" size="xsmall">
                        completed: {rawAffordabilityData?.result?.completed}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        errorMessage:{" "}
                        {rawAffordabilityData?.result?.errorMessage}{" "}
                      </Text>
                    </Box>
                  )}
                </Box>
              </Box>
            </AccordionPanel>
          </Accordion>

          <Accordion>
            <AccordionPanel
              background={settings.darkMode ? "altSecondary" : "background"}
              round="small"
              label={
                <Text color="white" weight="bold" margin="small">
                  [DEBUG] Other calculated information
                </Text>
              }
            >
              <Box
                background={settings.darkMode ? "altSecondary" : "background"}
                round="small"
                style={{ borderBottom: "none" }}
              >
                <Box margin="small">
                  <Text weight="bold" color="white">
                    Other calculated information
                  </Text>

                  <Text color="white" margin="xsmall">
                    {content.calculatorResult.accordion.help_label}
                  </Text>
                  <Text color="white" margin="xsmall" size="xsmall">
                    Mortgage Shared Ownership: {mortgageSharedOwnership}{" "}
                  </Text>
                  <Text color="white" margin="xsmall" size="xsmall">
                    Loan Amount: £{formValues?.loanAmount}{" "}
                  </Text>
                  <Text color="white" margin="xsmall" size="xsmall">
                    Shared Ownership PCM: £{sharedOwnershipPCM}{" "}
                  </Text>

                  {rawHTBData?.totalEquityToPurchase && (
                    <Box>
                      <Text color="white" margin="xsmall" size="xsmall">
                        totalEquityToPurchase:{" "}
                        {rawHTBData.totalEquityToPurchase}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        applicantsEquityValue:{" "}
                        {rawHTBData.applicantsEquityValue}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        equityLoan: {rawHTBData.equityLoan}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        equityLoanPercentage:{" "}
                        {rawHTBData.equityLoanPercentage}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        customerCashContribution:{" "}
                        {rawHTBData.customerCashContribution}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        sufficientDepositForLender:{" "}
                        {rawHTBData.sufficientDepositForLender}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        mortgageRequirement: {rawHTBData.mortgageRequirement}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        mortagePercentage: {rawHTBData.mortagePercentage}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        incomeMultiple: {rawHTBData.mortgageRequirement}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        IMSincomeMultiple: {rawHTBData.IMSincomeMultiple}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        MortgagePCM: {rawHTBData.MortgagePCM}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        equityRentServiceCharge:{" "}
                        {rawHTBData.equityRentServiceCharge}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        debtToHouseholdIncomeRatio:{" "}
                        {rawHTBData.debtToHouseholdIncomeRatio}{" "}
                      </Text>
                    </Box>
                  )}

                  <Text color="white" margin="xsmall">
                    {content.calculatorResult.accordion.shared_label}
                  </Text>
                  <Text color="white" margin="xsmall" size="xsmall">
                    Mortgage Help To Buy: {mortgageHelpToBuy}{" "}
                  </Text>
                  <Text color="white" margin="xsmall" size="xsmall">
                    Loan Amount: £{formValues?.loanAmount}{" "}
                  </Text>
                  <Text color="white" margin="xsmall" size="xsmall">
                    Help To Buy PCM: £{helpToBuyPCM}{" "}
                  </Text>

                  {rawSOData?.totalEquityPurchase && (
                    <Box>
                      <Text color="white" margin="xsmall" size="xsmall">
                        totalEquityPurchase: {rawSOData.totalEquityPurchase}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        totalEquityPurchaseNumeric:{" "}
                        {rawSOData.totalEquityPurchaseNumeric}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        shareValue: {rawSOData.shareValue}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        customerDeposit: {rawSOData.customerDeposit}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        sufficientDepositForLender:{" "}
                        {rawSOData.sufficientDepositForLender}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        mortgageAmount: {rawSOData.mortgageAmount}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        incomeMultiple: {rawSOData.incomeMultiple}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        mortgagePCM: {rawSOData.mortgagePCM}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        monthlyRentPCM: {rawSOData.monthlyRentPCM}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        serviceChargePCM: {rawSOData.serviceChargePCM}{" "}
                      </Text>
                      <Text color="white" margin="xsmall" size="xsmall">
                        totalMonthPaymentsPCM:{" "}
                        {rawSOData.totalMonthPaymentsPCM}{" "}
                      </Text>
                    </Box>
                  )}
                </Box>
              </Box>
            </AccordionPanel>
          </Accordion>
          </Box>
        </>
      )}
      {!showLoading && !editResults && hasAffordability && (
        <WizardSignup
          formValues={formValues}
          submissionId={props.submissionId}
          submissionUUID={props.uuid}
        />
      )}
    </>
  );
}

export default CalculatorResults;
