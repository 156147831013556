/* eslint-disable */
import React, { useEffect } from 'react'

import Box from '../components/simple/box/Box.js'
import Text from '../components/simple/text/Text.js'
import Image from '../components/simple/image/Image.js'

// Resources
import { content } from '../resources/content'

function Success (props) {
  var boxSize = '500px'
  var fontSize = 'xxlarge'
  if (props.window.width < 550) {
    boxSize = '300px'
    fontSize = 'medium'
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    props.setIsSuccessPage(true)
  }, [])

  return (
    <Box margin='20px'>
      <Box
        width={boxSize}
        height={boxSize}
        alignSelf='center'
        margin={{ top: 'medium' }}
        pad='50px'
        round='50%'
        border={{ color: '#fff', size: 'medium' }}
        background={{
          image: 'url(/success-graphic.svg?r=0001)'
        }}
      >

        <Text color='white' style={{ marginTop: '60%'}} size={fontSize} weight='bold' textAlign='center'>{content.success.title}</Text>
      </Box>
      <Box width='100%' height='small' pad={{ vertical: 'medium' }} margin={{ top: 'xlarge' }}>
        <Image fit='contain' src='/logo-white.png?r=001' />
      </Box>
    </Box>
  )
}

export default Success
