/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { Box as GBox } from 'grommet'

/**
 * `Box` renders a Grommet Box component
 */
class Box extends React.Component {
  render () {
    return (
      <GBox
        {...this.props}
      />)
  }
}

Box.propTypes = {
  /**
   * Custom label to be used by screen readers. When provided, an aria-label will be added to the element.  
   *   
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * How to align the contents along the cross axis.  
   *   
   * "start"  
   * "center"  
   * "end"  
   * "baseline"  
   * "stretch"
   */
  align: PropTypes.string,
  /**
   * How to align the contents when there is extra space in the cross axis  
   *   
   * "start"  
   * "center"  
   * "end"  
   * "between"  
   * "around"  
   * **"stretch"**
   */
  alignContent: PropTypes.string,
  /**
   * How to align along the cross axis when contained in a Box or along the column axis when contained in a Grid.  
   *   
   * "start"  
   * "center"  
   * "end"  
   * "stretch"
   */
  alignSelf: PropTypes.string,
  /**
   * Animation effect(s) to use. 'duration' and 'delay' should be in milliseconds.  
   * 'jiggle' and 'pulse' types are intended for small elements, like icons.  
   *   
   * "fadeIn"  
   * "fadeOut"  
   * "jiggle"  
   * "pulse"  
   * "slideUp"  
   * "slideDown"  
   * "slideLeft"  
   * "slideRight"  
   * "zoomIn"  
   * "zoomOut"  
   * {  
   * &nbsp;"type": "...",  
   * &nbsp;"delay": 0,  
   * &nbsp;"duration": 1000,  
   * &nbsp;"size": "xsmall"  
   * }  
   * ["fadeIn", "slideDown", "..."]
   */
  animation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  /**
   * The DOM tag or react component to use for the element.  
   *   
   * "a parent grid area name"  
   * "string"  
   * function
   */
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  /**
   * Either a color identifier to use for the background color.  
   * For example: 'neutral-1'. Or, a 'url()' for an image. Dark is not needed if color is provided.  
   *   
   * "neutral-1"  
   * "url(//my.com/assets/img.png)"  
   * {  
   * &nbsp;"color": "neutral-1",  
   * &nbsp;"dark": true,  
   * &nbsp;"opacity": true,  
   * &nbsp;"position": "bottom",  
   * &nbsp;"repeat": "no-repeat",  
   * &nbsp;"size": "cover",  
   * &nbsp;"image": "url(//my.com/assets/img.png)"  
   * }  
   * {"dark": "light-2", "light": "dark-2"}  
   *  
   * where opacity could be:  
   *  &nbsp;"weak"  
   *  &nbsp;"medium"  
   *  &nbsp;"strong"  
   *  &nbsp;true  
   *  
   * where position could be:  
   *  &nbsp;any CSS for background-position  
   *  
   * where repeat could be:  
   *  &nbsp;"no-repeat"  
   *  &nbsp;"repeat"  
   *  &nbsp;"string"  
   *  
   * where size could be:  
   *  &nbsp;"cover"  
   *  &nbsp;"contain"  
   *  &nbsp;"string"
   */
  background: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * A fixed or relative size along its container's main axis.  
   *   
   * "xxsmall"  
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "full"  
   * "1/2"  
   * "1/3"  
   * "2/3"  
   * "1/4"  
   * "2/4"  
   * "3/4"  
   * "auto"
   */
  basis: PropTypes.string,
  /**
   * Include a border. 'between' will place a border in the gap between child elements.  
   * You must have a 'gap' to use 'between'  
   *   
   * true  
   * false  
   * "top"  
   * "left"  
   * "bottom"  
   * "right"  
   * "between"  
   * "horizontal"  
   * "vertical"  
   * "all"  
   * {  
   * &nbsp;"color": "border",  
   * &nbsp;"size": "medium",  
   * &nbsp;"style": "dashed",  
   * &nbsp;"side": "all"  
   * }  
   *  
   * where size could be:  
   * &nbsp;"xsmall"  
   * &nbsp;"small"  
   * &nbsp;"medium"  
   * &nbsp;"large"  
   * &nbsp;"xlarge"  
   * &nbsp;"any CSS size"  
   *  
   * where style could be:  
   * &nbsp;"solid"  
   * &nbsp;"dashed"  
   * &nbsp;"dotted"  
   * &nbsp;"double"  
   * &nbsp;"groove"  
   * &nbsp;"ridge"  
   * &nbsp;"inset"  
   * &nbsp;"outset"  
   * &nbsp;"hidden"  
   *  
   * where side could be:  
   * &nbsp;"top"  
   * &nbsp;"left"  
   * &nbsp;"bottom"  
   * &nbsp;"right"  
   * &nbsp;"horizontal"  
   * &nbsp;"vertical"  
   * &nbsp;"all"
   */
  border: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The orientation to layout the child components in.  
   *   
   * "row"  
   * **"column"**  
   * "row-responsive"  
   * "row-reverse"  
   * "column-reverse"
   */
  direction: PropTypes.string,
  /**
   * Elevated height above the underlying context, indicated via a drop shadow.  
   *   
   * **"none"**  
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "any custom elevation name in the current theme"
   */
  elevation: PropTypes.string,
  /**
   * Whether the width and/or height should fill the container.  
   *   
   * "horizontal"  
   * "vertical"  
   * true  
   * false
   */
  fill: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  /**
   * Whether flex-grow and/or flex-shrink is true and at a desired factor.  
   *   
   * "grow"  
   * "shrink"  
   * true  
   * false  
   * {  
   * &nbsp;&nbsp;grow: number,  
   * &nbsp;&nbsp;shrink: number  
   * }
   */
  flex: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object
  ]),
  /**
   * When interactive via 'onClick', whether it should receive a focus outline.  
   *   
   * **true**
   * false
   */
  focusIndicator: PropTypes.bool,
  /**
   * The amount of spacing between child elements.  
   * This should not be used in conjunction with 'wrap' as the gap elements will not wrap gracefully.  
   *   
   * "none"  
   * "xxsmall"  
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "any CSS size"
   */
  gap: PropTypes.string,
  /**
   * The name of the area to place this inside a parent Grid.  
   *   
   * "a parent grid area name"
   */
  gridArea: PropTypes.string,
  /**
   * A fixed height.  
   *   
   * "xxsmall"  
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "xxlarge"  
   * {"min": "...", "max": "..."}  
   * "any CSS size"
  */
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * When 'onClick' has been specified, the hover indicator to apply when the user is mousing over the box.  
   *   
   *  - true  
   *  - **false**  
   *  - "string"
   *  - "background"  
   *  - {  
   *  - &nbsp;color: "string,"  
   *  - &nbsp;dark:  
   *  - &nbsp;&nbsp;"boolean"  
   *  - &nbsp;&nbsp;"string,"  
   *  - &nbsp;image: "string,"  
   *  - &nbsp;light: "string,"  
   *  - &nbsp;position: "string,"  
   *  - &nbsp;opacity:  
   *  - &nbsp;&nbsp;"string"  
   *  - &nbsp;&nbsp;"boolean"  
   *  - &nbsp;&nbsp;"number"  
   *  - &nbsp;&nbsp;"weak"  
   *  - &nbsp;&nbsp;"medium"  
   *  - &nbsp;&nbsp;"strong,"  
   *  - &nbsp;repeat:  
   *  - &nbsp;&nbsp;"no-repeat"  
   *  - &nbsp;&nbsp;"repeat"  
   *  - &nbsp;&nbsp;"string,"  
   *  - &nbsp;size:  
   *  - &nbsp;&nbsp;"cover"  
   *  - &nbsp;&nbsp;"contain"  
   *  - &nbsp;&nbsp;"string"  
   *  - }
   */
  hoverIndicator: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object
  ]),
  /**
   * How to align the contents along the main axis.  
   *   
   * "around"  
   * "between"  
   * "center"  
   * "end"  
   * "evenly"  
   * "start"  
   * "stretch"
   */
  justify: PropTypes.string,
  /**
   * The amount of margin around the component.  
   * An object can be specified to distinguish horizontal margin, vertical margin, and margin on a particular side.  
   *   
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * &nbsp;{  
   * &nbsp;&nbsp;"vertical": "...",  
   * &nbsp;&nbsp;"horizontal": "...",  
   * &nbsp;&nbsp;"top": "...",  
   * &nbsp;&nbsp;"bottom": "...",  
   * &nbsp;&nbsp;"left": "...",  
   * &nbsp;&nbsp;"right": "..."  
   * &nbsp;}
   */
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Click handler. Setting this property adds additional attributes to the DOM for accessibility. 
   */
  onClick: PropTypes.func,
  /**
   * Box overflow.  
   *   
   * "auto"  
   * "hidden"  
   * "scroll"  
   * "visible"  
   * "any CSS overflow"  
   * {"vertical": "...", "horizontal": "..."}
   */
  overflow: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The amount of padding around the box contents.  
   * An object can be specified to distinguish horizontal padding, vertical padding, and padding on a particular side of the box.  
   *   
   * **"none"**  
   * "xxsmall"  
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "any CSS size"  
   * {  
   * nbsp;"vertical": "...",  
   * nbsp;"horizontal": "...",  
   * nbsp;"top": "...",  
   * nbsp;"bottom": "...",  
   * nbsp;"left": "...",  
   * nbsp;"right": "..."  
   * }
   */
  pad: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Whether margin, pad, and border sizes should be scaled for mobile environments.  
   *   
   * **true**  
   * false
   */
  responsive: PropTypes.bool,
  /**
   * How much to round the corners.  
   *   
   * true  
   * **false**  
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "full"  
   * "any CSS size"  
   * {"size": "...", "corner": "top-left"}  
   *  
   * where corner could be:  
   * "top"  
   * "left"  
   * "bottom"  
   * "right"  
   * "top-left"  
   * "top-right"  
   * "bottom-left"  
   * "bottom-right"
   */
  round: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * The DOM tag to use for the element. NOTE: This is deprecated in favor of indicating the DOM tag via the 'as' property.  
   *   
   * "string"  
   * "function"
   */
  tag: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func
  ]),
  /**
   * A fixed width.  
   *   
   * "xxsmall"  
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "xxlarge"  
   * {"min": "...", "max": "..."}  
   * "any CSS size"
   */
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Whether children can wrap if they can't all fit.  
   *   
   * true  
   * **false**  
   * reverse
   */
  wrap: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ])
}

export default Box
