/* eslint-disable */
import ApiService from "./ApiService";

class FormService {

  getResponses = async (apiToken, params, where) => {
    var url = "/api/formresponse";

    if (where) {
      params.where = where;
    }

    if (!params?.siteId) {
      params.siteId = '66268ec68c36dccb5ea1477f';
    }

    try {
      var responses = await ApiService.get(apiToken, url, params);
    } catch (err) {
      console.log(err);
    }
    console.log("FormService:getResponses ", responses);
    return responses;
  };

  makeSubmissions = async (apiToken, data, params = {}, uuid = null) => {
    var url = "/api/formsubmission";

    if (uuid) { data.uuid = uuid }

    if (document.location.href.includes('source=')) {
      let reg = /source=([^&]*)/gm.exec(document.location.href)
      data.responses.push({
        questionKey: "source_tag_2", questionText: "source_tag", response: reg[1]
      });
      data.responses.push({
        questionKey: "source_tag", questionText: "source_tag", response: process.env.REACT_APP_SOURCE_TAG
      })
    }
    else if (!data?.response?.find(x => x.questionKey === 'source_tag')) {
      data.responses.push({
        questionKey: "source_tag", questionText: "source_tag", response: process.env.REACT_APP_SOURCE_TAG
      })
    } else {
      data.responses.push({
        questionKey: "source_tag", questionText: "source_tag", response: 'n/a'
      })
    }
    var options = {
      data: data,
    };

    try {
      var response = await ApiService.post(apiToken, url, params, options);
    } catch (err) {
      console.log(err);
    }
    console.log("FormService:makeSubmissions ", response);

    return response;
  };

  updateSubmission = async (apiToken, submissionId, data, uuid = null) => {
    var url = `/api/formsubmission/${submissionId}`
    if (uuid) { url  = `/api/formsubmission/signup/${uuid}` }

    var params = {};

    var options = {
      data: data,
    };

    try {
      var result = await ApiService.put(apiToken, url, params, options);
    } catch (err) {
      console.log(err);
    }
    return result;
  };

  getMabs = async (apiToken, id, params = {}, where) => {
    var url = "/meridian/mabs/signup/" + id;
    if (where) {
      params.where = where;
    }

    if (!params?.siteId) {
      params.siteId = '66268ec68c36dccb5ea1477f';
    }
    try {
      var responses = await ApiService.get(apiToken, url, params);
    } catch (err) {
      console.log(err);
    }
    return responses;
  };

  getLandg = async (apiToken, id, params = {}, where) => {
    var url = "/meridian/landg/signup/" + id;
    if (where) {
      params.where = where;
    }

    if (!params?.siteId) {
      params.siteId = '66268ec68c36dccb5ea1477f';
    }

    try {
      var responses = await ApiService.get(apiToken, url, params);
    } catch (err) {
      console.log(err);
    }
    return responses;
  };
}
export default new FormService();
