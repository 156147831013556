/* eslint-disable */
import { toast } from 'react-toastify'

class NotificationService {
  info = (message) => {
    toast.info(message)
  }

  success = (message) => {
    toast.success(message)
  }

  warning = (message) => {
    toast.warning(message)
  }

  error = (message) => {
    toast.error(message)
  }

  default = (message) => {
    toast(message)
  }

  dark = (message) => {
    toast.dark(message)
  }
}

export default new NotificationService()
