/* eslint-disable */
import React, { useState, useEffect } from "react";
// Grommet
import { Accordion, AccordionPanel } from "grommet";

// 3rd Party
import Nope from "nope-validator";
import { useHistory } from "react-router-dom";
import moment from "moment";

// Components
import Box from "../components/simple/box/Box.js";
import Button from "../components/simple/button/Button";
import Form from "../components/simple/form/Form";
import Text from "../components/simple/text/Text";
import Call from "../components/compound/signup.js/Call.js";
import Consent from "../components/compound/signup.js/Consent.js";
import CalculatorResults from "../components/compound/CalculatorResults.js";

// services
import NotificationService from "../services/NotificationService";

// Resources
import { content } from "../resources/content";

function CalculatorResult(props) {
  const history = useHistory();
  const [formValues, setFormValues] = useState({});
  const [submissionId, setSubmissionId] = useState();

  const FormSchema = Nope.object().shape({
    dateToCall: Nope.date()
      .after(
        moment().subtract(1, "days").toDate(),
        content.call.date_future_text
      )
      .required(),
    timeToCall: Nope.string(),
    notes: Nope.string(),
    acceptTC: Nope.string().required().atLeast(2),
  });

  const validateFormField = (key) => {
    const error = FormSchema.validate(formValues);

    // return an error
    if (error?.[key]) {
      return {
        message: error[key],
        status: "error",
      };
    }
  };

  const onSubmit = async () => {
    // history.push('/success')
  };

  useEffect(() => {
    props.setIsSuccessPage(false);

    if (history?.location?.data?.submissionId) {
      setSubmissionId(history.location.data.submissionId);
    } else {
      NotificationService.warning(
        "You've accessed the temporary override for submission 1659."
      );
      setSubmissionId(1659);
    }
  }, []);

  var inputBottomMargin = "medium";
  var inputTwoColumnWidth = "50%";
  if (props.window.width < 840) {
    inputBottomMargin = "large";
    inputTwoColumnWidth = "100%";
  }

  return (
    <>
      <Form
        value={formValues}
        validate="blur"
        onChange={(nextValue) => setFormValues(nextValue)}
        onSubmit={() => onSubmit()}
      >
        <Box gap="medium">
          <CalculatorResults submissionId={submissionId} />
          <Text
            color="white"
            size="large"
            margin={{ top: "small", bottom: "small" }}
            style={{ marginBottom: "50px", marginTop: "50px" }}
          >
            {content.calculatorResult.description_text}
          </Text>
          <Call
            validateFormField={validateFormField}
            inputTwoColumnWidth={inputTwoColumnWidth}
            inputBottomMargin={inputBottomMargin}
          />
          <Consent
            validateFormField={
              validateFormField
            } /* formValues={history.location.data.formValues} */
          />
        </Box>
        <Box
          direction="row-responsive"
          margin={{ top: "medium", bottom: "medium" }}
        >
          <Button
            label={content.calculatorResult.submit_button_label}
            secondary
            type="submit"
            fill
          />
        </Box>
        <Text
          color="white"
          size="xsmall"
          margin={{ top: "small", bottom: "small" }}
        >
          {content.calculatorResult.caveat_text1}
          <br /><br />
          {content.calculatorResult.caveat_text2}
        </Text>
      </Form>
    </>
  );
}

export default CalculatorResult;
