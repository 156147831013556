/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { Image as GImage } from 'grommet'

/**
 * `Image` renders a Grommet Image component
 */
class Image extends React.Component {
  render () {
    return (
      <GImage
        {...this.props}
      />)
  }
}

Image.propTypes = {
  /**
   * Custom label to be used by screen readers. When provided, an aria-label will be added to the element.  
   *   
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * How to align along the cross axis when contained in a Box or along the column axis when contained in a Grid.  
   *   
   * "start"  
   * "center"  
   * "end"  
   * "stretch"
   */
  alignSelf: PropTypes.string,
  /**
   * Specifies the URL of the fallback image used when src is failing to load  
   */
  fallback: PropTypes.string,
  /**
   * Whether the width and/or height should fill the container.  
   *   
   * "horizontal"  
   * "vertical"  
   * true  
   * false
   */
  fill: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  /**
   * How the image fills its container.  
   *   
   * "cover"  
   * "contain"
   */
  fit: PropTypes.string,
  /**
   * The name of the area to place this inside a parent Grid.  
   *   
   * "a parent grid area name"
   */
  gridArea: PropTypes.string,
  /**
   * The amount of margin around the component.  
   * An object can be specified to distinguish horizontal margin, vertical margin, and margin on a particular side.  
   *   
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * &nbsp;{  
   * &nbsp;&nbsp;"vertical": "...",  
   * &nbsp;&nbsp;"horizontal": "...",  
   * &nbsp;&nbsp;"top": "...",  
   * &nbsp;&nbsp;"bottom": "...",  
   * &nbsp;&nbsp;"left": "...",  
   * &nbsp;&nbsp;"right": "..."  
   * &nbsp;}
   */
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * Transparency of the image.  
   *   
   * "weak"  
   * "medium"  
   * "strong"  
   * "string"  
   * true  
   * false
   */
  opacity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ])
}

export default Image
