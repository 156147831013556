/* eslint-disable */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Box from '../components/simple/box/Box.js'
import Image from '../components/simple/image/Image.js'
import Text from '../components/simple/text/Text'
import Anchor from '../components/simple/anchor/Anchor.js'

function Sidebar (props) {
  var width = 'medium'

  const telephone = process.env.REACT_APP_CONTACT_NO;

  const currentDate = new Date().toLocaleDateString();

  if (props.window.width < 1124) {
    width = '300px'
  }

  if (props.window.width < 840) {
    return null
  }

  return (
    <Box
      gap='xsmall'
      width={width}
      pad={{
        horizontal: 'medium',
        bottom: 'large'
      }}
      background='rgba(228, 188, 65, 0.85)'
      elevation='small'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: '40px'
      }}
      border={{ color: 'secondary', size: 'small' }}
      round='none'
    >
      <Box alignItems='center' textAlign='center' id='central_box' >
        <Box width='100%' height='small' pad={{ vertical: 'medium' }}>
          <Image fit='contain' src='/logo-white.png?r=001' />
        </Box>
        <Text textAlign='center' weight='bold' color='white' size='large'>Let Us Help You Buy Your Dream Home</Text>
        <Text textAlign='center' color='white' size='medium' margin={{ top: 'large' }}>CALL US ON</Text>
        <Text textAlign='center' color='white' size='xxlarge' weight='bold' margin={{ top: 'xsmall' }}>{telephone}</Text>
        <Text textAlign='center' color='white' size='medium' weight='bold'>Open 7 days a week</Text>
        <Text textAlign='center' color='white' size='medium' weight='bold'>10am – 6pm</Text>
      </Box>

      <Box id='google_review' background='#fff' elevation='standard' round='small' pad='small' margin={{ top: 'large', horizontal: 'small' }}>
      <Box overflow='hidden' alignSelf='center' direction='row' margin={{ top: 'small' }}>
      <Anchor target='blank' href='https://www.google.com/search?q=meridian+mortgages&source=hp&ei=rWs9YtrvBKLE8gLDpZ6ABA&iflsig=AHkkrS4AAAAAYj15vUtf87KWABU8DjSybV2ywtwWzOly&gs_ssp=eJzj4tZP1zcsSUszN05JN2C0UjWoMLEwNzczMUw2S05NNrOwNLUyqDBONUxNMTZOTUo0Mk9KTU3zEspNLcpMyUzMU8jNLypJT0xPLQYA7RAWWQ&oq=meridian+mor&gs_lcp=Cgdnd3Mtd2l6EAMYADILCC4QgAQQxwEQrwEyBQgAEIAEMgUIABCABDIFCAAQgAQyCwguEIAEEMcBEK8BMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQ6DgguEIAEELEDEMcBEKMCOgsIABCABBCxAxCDAToRCC4QgAQQsQMQgwEQxwEQ0QM6CAguEIAEELEDOggIABCABBCxAzoICC4QgAQQ1AI6EQguEIAEELEDEMcBENEDENQCOgsILhCABBCxAxCDAToRCC4QgAQQsQMQxwEQowIQ1AI6CAguELEDEIMBOgsILhCABBCxAxDUAjoOCC4QgAQQsQMQxwEQ0QM6DgguEIAEEMcBEK8BENQCOgUILhCABDoKCC4QxwEQowIQCjoECAAQClAAWPoKYP4XaABwAHgAgAHCAogBqA6SAQc0LjUuMi4xmAEAoAEB&sclient=gws-wiz#lrd=0x4877641c6cec6895:0x3e1ed33eba27beef,1,,,' style={{ textDecoration: 'none' }}>
          <Box alignSelf='start' style={{ width: '100%' }}>
            <Image fit='contain' src='/google.png' style={{ width: '100%', height: 'auto' }} />
          </Box>
          <Text size='xsmall' alignSelf='center' color='lightGrey' textAlign='center' margin={{ top: 'small' }}>
            We have a 4.8 rating on Google as of {currentDate}
              </Text>
        </Anchor>
      </Box>
      </Box>
    </Box>
  )
}

export default Sidebar
