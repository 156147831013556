/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

import Box from '../../simple/box/Box'
import RadioButtonGroup from '../../simple/input/RadioButtonGroup'
import Text from '../../simple/text/Text'
import Image from '../../simple/image/Image'

function OptionButtons (props) {
  const valueArray = []

  const buildValueArray = () => {
    let i = props.minRange

    while (i <= props.maxRange) {
      valueArray[i] = i
      i = i + props.step
    }
  }

  buildValueArray()

  return (
    <RadioButtonGroup
      direction='row'
      {...props}
    >
      {(option, { checked, hover }) => {
        let background
        if (checked) background = props.buttonActive ? props.buttonActive : 'secondary'
        else if (hover) background = props.buttonHover ? props.buttonHover :  'altSecondary'
        else background = props.buttonBackground ? props.buttonBackground : 'tertiary'

        return (
          <Box
            background={background}
            pad={{
              horizontal: 'small',
              vertical: 'xxsmall',
            }}
            round='small'
            margin={{ top: 'xxsmall', bottom: 'xxsmall' }}
            elevation='standard'
            height={props.buttonHeight}
            width={props.buttonWidth}
            style={{
              maxWidth: props.buttonMaxWidth,
              alignItems: 'center',
              justifyContent: 'center',
              ...props.buttonStyles
            }}

          >
            {option.iconUrl && <Box height='75px'><Image fit='contain' alignSelf='center' src={option.iconUrl} margin={{ vertical: 'small' }} /></Box>}
            <Text color='white' textAlign='center' weight={props?.fontWeight || 'bold'} size={props?.fontSize || 'medium'}>{option?.label || option}</Text>
          </Box>
        )
      }}
    </RadioButtonGroup>)
}

OptionButtons.defaultProps = {
  options: ['Yes', 'No']
}

OptionButtons.propTypes = {
  /**
   * group name
   */
  name: PropTypes.string
}

export default OptionButtons
