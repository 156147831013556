/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { TextInput as GTextInput } from 'grommet'

/**
 * `TextInput` renders a Grommet TextInput box
 */
class TextInput extends React.Component {
  render () {
  // const onChange = (input) => this.props.onChange(input)

    return (
      <GTextInput
        {...this.props}
        // onChange={(input) => onChange(input)}
      />)
  }
}

TextInput.propTypes = {
  /**
   * Custom title to be used by screen readers.  
   *   
   * "a user friendly label for screen readers"
   */
  a11yTitle: PropTypes.string,
  /**
   * How to align the drop  
   *   
   * &nbsp;{  
   * &nbsp;&nbsp;top:    
   * &nbsp;&nbsp;&nbsp;"top"  
   * &nbsp;&nbsp;&nbsp;"bottom,"  
   * &nbsp;&nbsp;bottom:  
   * &nbsp;&nbsp;&nbsp;"top"  
   * &nbsp;&nbsp;&nbsp;"bottom,"  
   * &nbsp;&nbsp;right:  
   * &nbsp;&nbsp;&nbsp;"left"  
   * &nbsp;&nbsp;&nbsp;"right,"  
   * &nbsp;&nbsp;left:  
   * &nbsp;&nbsp;&nbsp;"left"  
   * &nbsp;&nbsp;&nbsp;"right"  
   * &nbsp;}
   */
  dropAlign: PropTypes.object,
  /**
   * The height of the drop container.  
   *   
   * "xsmall"  
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "string"
   */
  dropHeight: PropTypes.string,
  /**
   * Any valid Drop prop.  
   */
  dropProps: PropTypes.object,
  /**
   * Target where any suggestions drop will be aligned to. This should be a React reference.  
   * Typically, this is not required as the drop will be aligned to the TextInput itself by default.
   */
  dropTarget: PropTypes.object,
  /**
   * Whether the plain text input should receive a focus outline.  
   */
  focusIndicator: PropTypes.bool,
  /**
   * An optional icon to show. This could be used to provide an indication of what kind of input is expected,  
   * like an email icon, or what the input will be used for, like a search icon.  
   *   
   * "element"
   */
  icon: PropTypes.element,
  /**
   * The id attribute of the input.
   */
  id: PropTypes.string,
  /**
   * Custom messages for TextInput. Used for accessibility by screen readers.  
   *   
   * {  
   * &nbsp;enterSelect:  
   * &nbsp;&nbsp;"string,"  
   * &nbsp;suggestionsCount:  
   * &nbsp;&nbsp;"string,"  
   * &nbsp;suggestionsExist:  
   * &nbsp;&nbsp;"string,"  
   * &nbsp;suggestionIsOpen:  
   * &nbsp;&nbsp;"string"  
   * &nbsp;}
   */
  messages: PropTypes.object,
  /**
   * The name of the attribute when in a Form or FormField.
   */
  name: PropTypes.string,
  /**
   * Function that will be called when the user types in the input.
   */
  onChange: PropTypes.func,
  /**
   * Function that will be called when the user selects a suggestion.  
   * The suggestion contains the object chosen from the supplied suggestions.
   */
  onSelect: PropTypes.func,
  /**
   * Function that will be called when the suggestions drop is closed.
   */
  onSuggestionsClose: PropTypes.func,
  /**
   * Function that will be called when the suggestions drop is opened.
   */
  onSuggestionsOpen: PropTypes.func,
  /**
   * Placeholder to use when no value is provided.  
   *   
   * "node"
   */
  placeholder: PropTypes.string,
  /**
   * Whether this is a plain input with no border or padding.  
   * Only use this when the containing context provides sufficient affordance
   */
  plain: PropTypes.bool,
  /**
   * Whether an icon should be reversed so that the icon is at the end of the input.
   */
  reverse: PropTypes.bool,
  /**
   * The size of the TextInput.  
   *   
   * "small"  
   * "medium"  
   * "large"  
   * "xlarge"  
   * "string"
   */
  size: PropTypes.string,
  /**
   * Suggestions to show. It is recommended to avoid showing too many suggestions and instead rely on the user to type more.  
   *   
   * [  
   * &nbsp;&nbsp;"string"  
   * &nbsp;&nbsp;{  
   * &nbsp;&nbsp;&nbsp;label: "node,"  
   * &nbsp;&nbsp;&nbsp;value: any  
   * &nbsp;&nbsp;}  
   * &nbsp;]
   */
  suggestions: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ])
  ),
  /**
   * What text to put in the input.
   */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default TextInput
