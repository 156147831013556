/* eslint-disable */
export const settings = {
  apiToken: 'eeae9eb2-be99-43ac-b922-6efd7168eb7b',
  siteId: '66268ec68c36dccb5ea1477f',
  userId: '662640c8414b5886fd94235b',
  debug: false,
  mockData: true,
  darkMode: true,
  showCalaBranding: false,
  requiredFQGeneralFields: [
    { questionKey: 'mortgageType', questionText: 'Mortgage Type', response: 'Purchase' },
    { questionKey: 'propertyType', questionText: 'Property Type', response: 'House' },
    { questionKey: 'termYears', questionText: 'Mortgage Term', response: '30' }
  ],
  requiredFQApplicantFields: [
    { questionKey: 'PlannedRetirementAge', questionText: 'Planned Retirement Age', response: '55' },
    { questionKey: 'ContractType', questionText: 'Contract Type', response: 'permanent' },
    { questionKey: 'Title', questionText: 'Title', response: 'mr' },
    { questionKey: 'Dob', questionText: 'Dob', response: '07/07/1990' },
    { questionKey: 'SelfEmploymentType', questionText: 'SelfEmployment Type', response: 'Employed' },
    { questionKey: 'ServiceYears', questionText: 'Current Years', response: 10 }
  ]
}
