/* eslint-disable */
import ApiService from './ApiService'

class CalculateService {
	/*
  fqformcalc = async (apiToken, params, data) => {
    const url = '/meridian/fqform/calculate'

    try {
      var result = await ApiService.post(apiToken, url, params, data)
    } catch (err) {
      console.log(err)
    }
    return result
  }
  */

  affordability = async (apiToken, params, data) => {
    const url = `/meridian/landg/signup/${data.id}`

	console.log(params);

    try {
      var result = await ApiService.get(apiToken, url, params, data)
    } catch (err) {
      console.log(err)
    }
    return result
  }

  sharedOwnership = async (apiToken, params, data) => {
    const url = '/meridian/fqform/calculate/shared-ownership'

    try {
      var result = await ApiService.post(apiToken, url, params, data)
    } catch (err) {
      console.log(err)
    }
    return result
  }

  helpToBuy = async (apiToken, params, data) => {
    const url = '/meridian/fqform/calculate/help-to-buy'

    try {
      var result = await ApiService.post(apiToken, url, params, data)
    } catch (err) {
      console.log(err)
    }
    return result
  }
}
export default new CalculateService()
