/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

// Grommet
import { Box } from 'grommet'

/**
 * `Divider` renders a divider line
 */
class Divider extends React.Component {
  render () {
    return (
      <Box
        border={{
          color: this.props.color,
          size: this.props.size,
          style: this.props.type,
          side: 'all'
        }}
        round={this.props.round}
        margin={this.props.margin}
        // width='xlarge'
        {...this.props}
      />)
  }
}

Divider.propTypes = {
  /**
   * The divider color
   */
  color: PropTypes.string,
  /**
   * The amount of margin around the component.
   * An object can be specified to distinguish horizontal margin, vertical margin, and margin on a particular side.
   *
   * "xsmall"
   * "small"
   * "medium"
   * "large"
   * "xlarge"
   * &nbsp;{
   * &nbsp;&nbsp;"vertical": "...",
   * &nbsp;&nbsp;"horizontal": "...",
   * &nbsp;&nbsp;"top": "...",
   * &nbsp;&nbsp;"bottom": "...",
   * &nbsp;&nbsp;"left": "...",
   * &nbsp;&nbsp;"right": "..."
   * &nbsp;}
   */
  margin: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  /**
   * How much to round the divider line.
   *
   * "xsmall"
   * "small"
   * "medium"
   * "large"
   * "xlarge"
   * "any CSS size"
   */
  round: PropTypes.string,
  /**
   * The width of the divider line.
   *
   * "xsmall"
   * "small"
   * "medium"
   * "large"
   * "xlarge"
   * "any CSS size"
   */
  size: PropTypes.string,
  /**
   * Type of divider.
   *
   * "solid"
   * "dashed"
   * "dotted"
   * "double"
   * "groove"
   * "ridge"
   * "inset"
   * "outset"
   * "hidden"
   */
  type: PropTypes.string
}

export default Divider
